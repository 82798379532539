// store.js
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers/authSlice';
import inactivityReducer from './reducers/inactivitySlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    inactivity: inactivityReducer,
  },
});

export default store;
