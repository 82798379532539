// import React, { Component, Suspense } from "react";
import React, { useState, useEffect,Suspense, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link,useNavigate,Navigate,useLocation } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { apiCalling } from "../../apiService";
import Logoimg from "../../image/icons/image-preview.png";
import $ from "jquery";
import AWS from "aws-sdk";


var imageFile;
var s3;

  const AddProcedure=(props) =>{
    const location = useLocation();
  const [procedureData, setprocedureData] = useState([]);
	const [procedureId, setprocedureId] = useState("");
	const [buyPrice, setbuyPrice] = useState("");
	const [hospitalId, sethospitalId] = useState('');
	const [isActive, setisActive] = useState(false);
	const [mtBuyPrice, setmtBuyPrice] = useState("");
	const [mtMaxPrice, setmtMaxPrice] = useState("");
	const [mtMinPrice, setmtMinPrice] = useState("");
	const [minPrice, setminPrice] = useState('');
  const [procedureDetails, setprocedureDetails] = useState("");
	const [imageNameWithUrl, setimageNameWithUrl] = useState("");
	const [hospitalProcedureId, sethospitalProcedureId] = useState("");
	const [procedureName, setprocedureName] = useState('');
	const [s3bucketAccessKey, sets3bucketAccessKey] = useState("");
	const [AWSAccessKeyId, setAWSAccessKeyId] = useState("");
	const [maxPrice, setmaxPrice] = useState("");
	const [bucket, setbucket] = useState('');
	const [bucketFolder, setbucketFolder] = useState('');
  const [region,setRegion]=useState( "us-east-1");
  const navigate = useNavigate();
  const [pharmaImagePath,setpharmaImagePath]=useState('');
  const [state, setState] = useState({});


  const handleClickCancel = (data) =>{
      navigate('/adminManagement/hospitalManagementList/procedurelist', {
         state:  data  
      });
    };
  


  
  useEffect(() => {
    procedureDropdown();
    //setprocedureId(location.state.procedureId)
    if (location.state.hospitalProcedureId) {
      getProcedureDetails();
    }
    imageUploadHandler();
  },[])
  const procedureDropdown=() =>{
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "addNewProcedure",
        hospitalId: location.state.hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      //setState({ procedureData: data.procedureData });
      setprocedureData(data.procedureData);
      $(".loader").hide();
    });
  }
  const getProcedureDetails =() =>{
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editHospitalProcedure",
        hospitalProcedureId: location.state.hospitalProcedureId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      sethospitalProcedureId(location.state.hospitalProcedureId);
      setbuyPrice(data.procedureData[0].buyPrice);
      sethospitalId(data.procedureData[0].hospitalId);
      setisActive(data.procedureData[0].isActive);
      setmaxPrice(data.procedureData[0].maxPrice);
      setminPrice(data.procedureData[0].minPrice);
      setmtBuyPrice(data.procedureData[0].mtBuyPrice);
      setmtMaxPrice(data.procedureData[0].mtMaxPrice);
      setmtMinPrice(data.procedureData[0].mtMinPrice);
      setprocedureDetails(data.procedureData[0].procedureDetails);
      setimageNameWithUrl(data.procedureData[0].imageNameWithUrl);
      setprocedureId(data.procedureData[0].procedureId);
      const isActive = document.querySelector("#isActive");
      isActive.value = data.procedureData[0].isActive === false ? 0 : 1;
      isActive.options[isActive.value].defaultSelected = true;
    });
    $(".loader").hide();
  }
  const changeHandler=(e)=> {
    //this.setState({ [e.target.id]: e.target.value });
    setState({ ...state, [e.target.id]: e.target.value });

  }
  const srcTypeImage=(ev) =>{
    ev.target.src = Logoimg;
  }
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      fileUpload(imageFile);
    }
  };
  const fileUpload=(file) =>{
    $(".loader").show();
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: AWSAccessKeyId,
      region:region,
    });
    var bucketParams = {
      Bucket:
       bucket +
        "/" +
        bucketFolder +
        "/" +
       pharmaImagePath,
    };
    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let S3BucketMainFolder = "";
    let fileName = uuidv4() + file.name;
    let fullFilePath = S3BucketMainFolder + fileName;
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
            setimageNameWithUrl(data.Location);
          $(".loader").hide();
          return null;
        }
      }
    );
  }
  const imageUploadHandler=()=> {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        
          sets3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
        
          setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
        
          setbucket(data.configPathDetails[0].PurpleHealthS3bucket);
        
          setbucketFolder(data.configPathDetails[0].S3BucketMainFolder);
       
          setpharmaImagePath(data.configPathDetails[0].pharmaImagePath);
        
      }
    });
  }
  const saveProcedure=()=> {
    var pro_id=$("#procedureId").val();
    $(".loader").show();
    if (procedureId === "" && pro_id==="") {
      alert("Procedure Name Required");
      return false;
    }
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveProcedure",
        buyPrice: $("#buyPrice").val() === "" ? "0" : $("#buyPrice").val(),
        maxPrice: $("#maxPrice").val() === "" ? "0" : $("#maxPrice").val(),
        minPrice: $("#minPrice").val() === "" ? "0" : $("#minPrice").val(),
        mtBuyPrice:
          $("#mtBuyPrice").val() === "" ? "0" : $("#mtBuyPrice").val(),
        mtMaxPrice:
          $("#mtMaxPrice").val() === "" ? "0" : $("#mtMaxPrice").val(),
        mtMinPrice:
          $("#mtMinPrice").val() === "" ? "0" : $("#mtMinPrice").val(),
        isActive: $("#isActive").val(),
        hospitalId: location.state.hospitalId,
        procedureDetails: $("#procedureDetails").val(),
        procedureImage: "",
        procedureId:procedureId ? procedureId: pro_id,
        hospitalProcedureId:location.state.hospitalProcedureId
          ? location.state.hospitalProcedureId
          : "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        alert("Procedure Saved Successfully");
         window.location.href = "/adminManagement/hospitalManagementList/procedurelist"
        $("#txtCancel").click();
        $(".loader").hide();
      }
    });
  }
  // render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container mb-5">
          {/* <div>
                <ul className="breadcrum-adminMangmnt">
                    <li><a href="/adminManagement/DCadmindashboard">Dashboard</a></li>
                    <li><a href="/adminManagement/DCadmindashboard">Doctor Management</a></li>
                    <li><a href="" className="current">Doctor Details</a></li>
                </ul>
          </div> */}
          <div className="smssetng-btn mb-4 mt-4">
            {/* <Navigate
              title="Procedure Listing"
              to={{
                pathname:
                  "/adminManagement/hospitalManagementList/procedurelist",
                state: { hospitalId: props.location.state.hospitalId },
              }}
            > */}
            {/* <Navigate to="/adminManagement/hospitalManagementList/procedurelist" state={{ hospitalId: props.location.state.hospitalId }} /> */}
            <Link to={{ pathname: "/adminManagement/hospitalManagementList/procedurelist"}}>
              <input
                type="submit"
                value="Procedure Listing"
                className="smssetng-svebtn"
              />
            </Link> 
            <input
              type="submit"
              value="Save"
              className="smssetng-svebtn"
              onClick={() => saveProcedure()}
            />
            {/* <Link
              title="Procedure Listing"
              to={{
                pathname:
                  "/adminManagement/hospitalManagementList/procedurelist",
                state: { hospitalId: props.location.state.hospitalId },
              }}
            > */}

<button 
                onClick={()=>handleClickCancel({ hospitalId: location.state.hospitalId })}
                 className="smssetng-cancelbtn"> 
                 Cancel
                 {/* <input
              //    id="txtCancel"
              //    type="submit"
              //    value="Cancel"
              //    className="smssetng-cancelbtn"
              //  /> */}
              </button>


             
            {/* </Link> */}
          </div>
          <div className="addHspPage-Wrapper">
            <Tabs>
              <TabList>
                <Tab>Procedure Details</Tab>
              </TabList>
              <TabPanel>
                <div className="container procedreouterPadng doctr-wraper-border">
                  <div className="smssetng-btn mb-4 mt-1 text-right">
                    <input
                      type="submit"
                      value="Save & Continue"
                      className="smssetng-svebtn"
                      onClick={() => saveProcedure()}
                    />
                  </div>
                  {/* <div className="procedhsp-contentdiv">
                    <label>Hospital Name</label>
                    <input type="text" className="hspInfofieldinput proinputMobwidt"/>
                  </div> */}
                  <div className="hsptaltimeHead">
                    <div className="profield-div">
                      <label>
                        Procedures <span className="hspifoMantory">*</span>
                      </label>
                      <select
                        name="procedureId"
                        id="procedureId"
                        className="hspInfofieldinput eductninputwidthdctr"
                        onChange={(e) => changeHandler(e)}
                      >
                        {location.state.hospitalProcedureId ? (
                          <option
                            value={
                              location.state.hospitalProcedureId
                            }
                          >
                            {location.state.procedureName}
                          </option>
                        ) : (
                          <option value="">Select</option>
                        )}
                        {procedureData.map((data) => (
                          <option value={data.procedureId}>
                            {data.procedureName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">
                        Min. Price <span className="priceinrtext">(INR)</span>
                      </label>
                      <input
                        type="text"
                        value={minPrice?minPrice : state.minPrice}
                        onChange={(e) => changeHandler(e)}
                        id="minPrice"
                        placeholder="Price"
                        className="hspInfofieldinput priceinputproduredetl prInpwidthMob"
                      />
                    </div>
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">
                        Max. Price <span className="priceinrtext">(INR)</span>
                      </label>
                      <input
                        type="text"
                        value={maxPrice?maxPrice:state.maxPrice}
                        onChange={(e) => changeHandler(e)}
                        id="maxPrice"
                        placeholder="Price"
                        className="hspInfofieldinput priceinputproduredetl prInpwidthMob"
                      />
                    </div>
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">
                        Buy Price <span className="priceinrtext">(INR)</span>
                      </label>
                      <input
                        type="text"
                        value={buyPrice?buyPrice:state.buyPrice}
                        onChange={(e) => changeHandler(e)}
                        id="buyPrice"
                        placeholder="Price"
                        className="hspInfofieldinput priceinputproduredetl prInpwidthMob"
                      />
                    </div>
                  </div>
                  <div className="hsptaltimeHead">
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">
                        MT Min. Price{" "}
                        <span className="priceinrtext">(USD)</span>
                      </label>
                      <input
                        type="text"
                        value={mtMinPrice?mtMinPrice:state.mtMinPrice}
                        onChange={(e) => changeHandler(e)}
                        id="mtMinPrice"
                        placeholder="Price"
                        className="hspInfofieldinput priceinputproduredetl prInpwidthMob"
                      />
                    </div>
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">
                        MT Max. Price{" "}
                        <span className="priceinrtext">(USD)</span>
                      </label>
                      <input
                        type="text"
                        value={mtMaxPrice?mtMaxPrice:state.mtMaxPrice}
                        onChange={(e) => changeHandler(e)}
                        id="mtMaxPrice"
                        placeholder="Price"
                        className="hspInfofieldinput priceinputproduredetl prInpwidthMob"
                      />
                    </div>
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">
                        MT Buy Price <span className="priceinrtext">(USD)</span>
                      </label>
                      <input
                        type="text"
                        value={mtBuyPrice?mtBuyPrice:state.mtBuyPrice}
                        onChange={(e) => changeHandler(e)}
                        id="mtBuyPrice"
                        placeholder="Price"
                        className="hspInfofieldinput priceinputproduredetl prInpwidthMob"
                      />
                    </div>
                    <div className="hsptimefieldDiv hsptimefieldDivMob-time">
                      <label htmlFor="">Status</label>
                      <select name="isActive" id="isActive">
                        <option value="0">Disabled </option>
                        <option value="1">Enabled </option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="hsptaltimeHead">
                    <div className="procedhsp-contentdiv procedhsp-contentdivWidth hsptimefieldDivMob-time">
                      <label>
                        Image<span className="hspifoMantory">*</span>
                      </label>
                      <div className="hspinfoimgaeuploadDiv procedufleupload-width">
                        <div className="hspinfo-btnupload">
                          <span>Upload</span>
                          <input
                            type="file"
                            className="hspinfoInputfieldupload"
                            onChange={this.fileChange}
                          />
                        </div>
                        <div id="imgDiv" style={{ display: "block" }}>
                          <img
                            height="30"
                            width="35"
                            id="image"
                            src={this.state.imageNameWithUrl}
                            alt="procedureImage"
                            onError={this.srcTypeImage}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-4 prcdre-padngmob">
                    <label className="label-hspservice">
                      Procedure Details
                    </label>
                    <div>
                      <textarea
                        name="procedureDetails"
                        value={procedureDetails?procedureDetails:state.procedureDetails}
                        id="procedureDetails"
                        cols="30"
                        rows="20"
                        className="textarea-hspservice"
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  // }
}
export default AddProcedure;