import React, { useEffect } from 'react';
import Header from "../layout/header";
import Footer from "../layout/footer";
import Banner from "./home-banner";
import ProvenResults from "./home-proven-results";
import HomeOurSolutions from "./home-our-solutions";
import HomeSuccess from "../home/digital-branding-success-stories";
import AboutTakeNextStep from './about-take-next-step';
import $ from "jquery";

const Home = () => {
  
  useEffect(() => {
    document.getElementById("home-menu").classList.add("active");
    
    // Handle click on #solutionsButton to scroll to #purplehealthfeatureheadID
    $("#solutionsButton").click(function () {
      $('html, body').animate({
        scrollTop: $("#purplehealthfeatureheadID").offset().top
      }, 100);
    });

    // Cleanup the event handler on unmount
    return () => {
      $("#solutionsButton").off('click');
    };
  }, []);

  return (
    <div className="ph-main-wrapper">
      <Header />
      <Banner />
      <ProvenResults />
      <HomeOurSolutions />
      <HomeSuccess />
      <AboutTakeNextStep />
      <Footer />
    </div>
  );
};

export default Home;
