import React, { useEffect, useState, Suspense } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
// import SiteSettingsSiteList from "../siteSettings/siteSettings-AddEdit-List";
import SiteSettingsSiteList from "../SiteSettingsv2/SiteSettings-AddEdit-List";
import SiteSettingsSiteUpdate from "../SiteSettingsv2/SiteSettings-AddEdit-Update";
// import SiteSettingsSiteUpdate from "../siteSettings/siteSettings-AddEdit-Update";
import AddHeaderLink from "../siteSettings/addHeaderLink";
import AddPromoCode from "../siteSettings/addPromoCode";
import AddFooterLink from "../siteSettings/addFooterLink";
import { apiCalling } from "../../apiService";

export default function SiteSettingsAddEdit() {
  const [state, setState] = useState({
    mode: "list",
    siteId: 0,
    currentPage: "Add/Edit Site",
    groupHospitalAdminId: null,
    navigation: [
      { pageName: "Dashboard", url: "/admin/dashboard" },
      { pageName: "Admin Management", url: "/admin/dashboardAdmin" },
      { pageName: "Site Settings", url: "/adminManagement/SiteSettings" },
      {
        pageName: "Add/Edit Site",
        url: "/adminManagement/SiteSettings-AddEdit",
        current: true,
      },
    ],
    imagePathData: {},
  });

  //! functions here
  useEffect(() => {
    getImagePath();
  }, []);

  const getImagePath = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: window.localStorage.getItem("siteId"),
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setState((props) => ({
          ...props,
          imagePathData: data.configPathDetails[0],
        }));
      }
    });
  };

  const handleModeChange = (
    mode,
    navigation,
    siteId = 0,
    currentPage = "",
    groupHospitalAdminId = null
  ) => {
    let popObj = {
      mode,
      navigation,
      siteId,
      currentPage,
      groupHospitalAdminId,
    };

    setState((props) => ({
      ...props,
      mode,
      navigation,
      siteId,
      currentPage,
      groupHospitalAdminId,
    }));
    // this.setState();
  };

  const handleView = () => {
    if (state.mode == "list") {
      return <SiteSettingsSiteList changeMode={handleModeChange} />;
    } else if (state.mode == "addNew") {
      return (
        <SiteSettingsSiteUpdate
          changeMode={handleModeChange}
          siteId={state.siteId}
          imagePathData={state.imagePathData}
          groupHospitalAdminId=""
        />
      );
    } else if (state.mode == "edit") {
      return (
        <SiteSettingsSiteUpdate
          // changeMode={handleModeChange}
          // siteId={state.siteId}
          // imagePathData={state.imagePathData}
          // groupHospitalAdminId={state.groupHospitalAdminId}

          changeMode={handleModeChange}
          siteIdProp={state.siteId}
          imagePathData={state.imagePathData}
          groupHospitalAdminIdProp={state.groupHospitalAdminId}
        />

      );
    } else if (state.mode == "addNewHeaderLink") {
      return (
        <AddHeaderLink
          changeMode={handleModeChange}
          siteId={state.siteId}
          groupHospitalAdminId={state.groupHospitalAdminId}
        />
      );
    } else if (state.mode == "addNewFooterLink") {
      return (
        <AddFooterLink
          changeMode={handleModeChange}
          siteId={state.siteId}
          groupHospitalAdminId={state.groupHospitalAdminId}
        />
      );
    } else if (state.mode == "addNewPromoCode") {
      return (
        <AddPromoCode
          changeMode={handleModeChange}
          siteId={state.siteId}
          groupHospitalAdminId={state.groupHospitalAdminId}
        />
      );
    } else {
      return <div>Error occured..</div>;
    }
  };

  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header />
          <div className="loader"></div>
          <div className="container">
            <div>
              <ul className="breadcrum-adminMangmnt">
                {state.navigation.map((nav) => (
                  <li key={nav.pageName}>
                    <a
                      href={nav.url}
                      className={nav.current ? "current" : null}
                    >
                      {nav.pageName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="head-hospMangmntList">{state.currentPage}</div>
            {handleView()}
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
}
