import React, { useState, useEffect, Suspense, useRef } from "react";
import Header from "../layout/header";
import { awsAPIUrlcommon, apiCalling } from "../apiService";
import Footer from "../layout/footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import view from "../image/icons/view.png";
import Switch from "react-switch";
import $ from "jquery";
import Edit from "../image/icons/edit.png";
import Pagination from "react-js-pagination";
// import "../css/";
const PollList = () => {
    const location = useLocation();
    const [pollListData, setPollListData] = useState([]);
    const [pollListDataDuplicate, setpollListDataDuplicate] = useState([]);
    const [pollListDataPagination, setpollListDataPagination] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [pageRange, setPageRange] = useState(10);
    const [siteList, setsiteList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [itemsPerPage] = useState(10);
    const [showList, setShowList] = useState(false);
    const [siteId, setSiteId] = useState(false);
    const [dropDownFlag, setdropDownFlag] = useState(false);
    const [pollMessage, setpollMessage] = useState();
    const [pollMessagetext, setpollMessageText] = useState('');
    const [choosedPollDataForNotificatoinEdit, setChoosedPollDataForNotificatoinEdit] = useState('');
    const [choosedPollDataForNotificatoinEditId, setChoosedPollDataForNotificatoinEditId] = useState('');

    const navigate = useNavigate();

    const handleInputChangeOnTypeMessage = (event) => {
        setpollMessageText(event.target.value)
    }
    const handleClickCreate = (data) => {
        navigate('/adminManagement/create-poll', {
            state: data
        });
    };
    const handleClickEdit = (data) => {
        navigate('/adminManagement/create-poll', {
            state: data
        });
    };
    const handleClickHistory = (data) => {
        navigate('/adminManagement/poll-History', {
            state: data
        });
    };

    const handleClickReport = (data) => {
        navigate('/adminManagement/poll-report', {
            state: data
        });
    };

    useEffect(() => {
        getSiteDetails()
    }, []);
    const getSiteDetails = () => {
        const siteDetail = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getAllSiteDetails",
            }),
        };
        $(".loader").show();
        apiCalling(siteDetail).then((data) => {
            if (data.success === "1") {
                setsiteList(data.siteData)
                $(".loader").hide();
                if (window.localStorage.getItem("role") === "ROLE_GROUPHOSPITALADMIN") {
                    const filteredResult = data.siteData.filter(item => {
                        return item.siteId.toString() === window.localStorage.getItem("siteId");
                    })
                    setFilteredData(filteredResult);
                    setSiteId(filteredResult && filteredResult[0] && filteredResult[0].siteId)
                    setInputValue(filteredResult && filteredResult[0] && filteredResult[0].siteName)
                    getFullListData(filteredResult && filteredResult[0] && filteredResult[0].siteId)
                    setShowList(false);
                    setdropDownFlag(true)
                }
            } else { $(".loader").hide(); }
        }).catch()
    }
    const getFullListData = (siteId) => {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "surveyList",
                "pollName": search,
                "isActive": "",
                "siteId": siteId ? siteId : window.localStorage.getItem("siteId"),
                "max": "",
                "offset": ""
            }),
        };
        awsAPIUrlcommon(apiJson).then((data) => {
            if (data.success === "1") {
                const temp = data.result && data.result.map((o, i) => ({
                    id: i + 1,
                    ...o
                }))
                setpollListDataDuplicate(temp.slice(0, 10))
                setPollListData(temp)
                setpollListDataPagination(temp)
                setTotalPageCount(data.totalCount)
                $(".loader").hide();
            } else {
                setpollListDataDuplicate([])
                setPollListData([])
                setpollListDataPagination([])
                setTotalPageCount(0)
                alert("Somethingwent Wrong While Fetching Data")
                $(".loader").hide();
            }
        }).catch((error) => {
            console.error("Error sending notification:", error);
            $(".loader").hide()
            alert("An error occurred while getting Details");
        });
    };
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    const handlePageChange = (pageNumber) => {
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = pollListData.slice(indexOfFirstItem, indexOfLastItem);
        setpollListDataDuplicate(currentItems);
        setCurrentPage(pageNumber);
    };
    const sendNotification = (data) => {
        let confirming = window.confirm("Do you really want to send Notification?")
        if (!confirming) {
            return
        }
        $(".loader").show();
        const postData = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "sendNotificationToDoctors",
                "pollId": data,
                "userId": window.localStorage.getItem("userId"),
            })
        };
        awsAPIUrlcommon(postData)
            .then((data) => {
                if (data.success === "1") {
                    getFullListData(siteId)
                    $(".loader").hide();
                    alert("Notification Successfully Delivered");
                } else {
                    $(".loader").hide();
                    alert("Something went wrong");
                }
            })
            .catch((error) => {
                $(".loader").hide();
                console.error("Error sending notification:", error);
                alert("An error occurred while sending notification");
            });
    };
    const sendReminderNotificationToDoctors = (data) => {
        let confirming = window.confirm("Do you really want to send Reminder Notification To Doctors?")
        if (!confirming) {
            return
        }
        $(".loader").show();
        const postData = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "sendReminderNotificationToDoctors",
                "pollId": data,
                "userId": window.localStorage.getItem("userId"),
            })
        };
        awsAPIUrlcommon(postData)
            .then((data) => {
                if (data.success === "1") {
                    $(".loader").hide();
                    alert("Reminder Notification Successfully Delivered");
                } else {
                    $(".loader").hide();
                    alert("Something went wrong");
                }
            })
            .catch((error) => {
                $(".loader").hide();
                console.error("Error sending notification:", error);
                alert("An error occurred while sending notification");
            });
    };
    const statusChanging = (data, activecase) => {
        const postData = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "activateDeactivatePoll",
                "pollId": data.pollId,
                "isActivePoll": activecase
            })
        };
        awsAPIUrlcommon(postData).then((response) => {
            if (response.success === "1") {
                getFullListData(siteId)
            }
        }).catch((error) => {
            console.error("Error fetching version list:", error);
        });
    };
    const updatePagination = (data) => {
        const pageCount = Math.ceil(data.length / itemsPerPage);
        setPageRange(Math.min(pageCount, 5)); // Update page range with minimum of initialPageRange and actual pageCount
        setTotalPageCount(pageCount);
        setCurrentPage(1); // Reset active page to 1
    };
    const handleInputChangeOnType = () => {
       // setSearch(event.target.value)
        const filteredResult = pollListData.filter(item => {
            return item.pollName.toLowerCase().includes(search);
        });
        updatePagination(filteredResult);
        setpollListDataPagination(filteredResult)
        setpollListDataDuplicate(filteredResult.slice(0, itemsPerPage));
        getFullListData(siteId)
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value.toLowerCase();
        setInputValue(inputValue);
        const filteredResult = siteList.filter(item => {
            return item.siteName.toLowerCase().includes(inputValue);
        });
        setFilteredData(filteredResult);
        setShowList(true);
    };
    const handleChange = (item) => {
        setInputValue(item.siteName); // Set the input value to the siteName
        getFullListData(item.siteId); // Call your function with the selected siteId
        setShowList(false);
        setSiteId(item.siteId)

    };

    const handleSiteIdCheck = () => {
        if (!inputValue) {
            alert("Please Choose Site First")
        }
    };
    const handleClickEditMessage = (data) => {
        setChoosedPollDataForNotificatoinEdit(data)
        $('.poll_popupdiv').show();
    }
    const pollNotificationMessage = (e) => {
        setpollMessage(e.target.value);
        if (e.target.value === "pollNotificationMessage") {
            setpollMessageText(choosedPollDataForNotificatoinEdit.pollNotificationMessage)
            setChoosedPollDataForNotificatoinEditId(choosedPollDataForNotificatoinEdit.pollNotificationMessageId)
        } else if (e.target.value === "pollReminderNotificationMessage") {
            setpollMessageText(choosedPollDataForNotificatoinEdit.pollReminderNotificationMessage)
            setChoosedPollDataForNotificatoinEditId(choosedPollDataForNotificatoinEdit.pollReminderNotificationMessageId)
        }
        else if (e.target.value === "pollSuccessMessage") {
            setpollMessageText(choosedPollDataForNotificatoinEdit.pollSuccessMessage)
            setChoosedPollDataForNotificatoinEditId(choosedPollDataForNotificatoinEdit.pollSuccessMessageId)
        }
    }
    const cancelClickMessage = () => {
        $('.poll_popupdiv').hide();
        setpollMessage('');
        setpollMessageText('');
    }

    const saveClickMessage = () => {
        var confirm = '';
        if (!pollMessage) {
            alert("Please Choose A  Notification Type First")
            return
        }
        if (!pollMessagetext) {
            alert("Please Fill Notification Message")
            return
        }

        if (pollMessage == 'pollNotificationMessage') {
            confirm = 'Do you really want to Customize Poll Notification Message?'
        }
        if (pollMessage == 'pollReminderNotificationMessage') {
            confirm = "Do you really want to Customize Poll Reminder Notification Message?"
        }
        if (pollMessage == 'pollSuccessMessage') {
            confirm = "Do you really want to Customize Poll Success Message?"
        }
        if (pollMessage) {
            let confirming = window.confirm(confirm)
            if (!confirming) {
                return
            }
        }
        if (pollMessage.length != 0) {
            // $(".loader").show();
            const postData = {
                method: "POST",
                body: JSON.stringify({
                    "functionName": "savePollNotificationDetails",
                    "pollId": choosedPollDataForNotificatoinEdit.pollId,
                    "createdBy": window.localStorage.getItem("userId"),
                    "pollNotificationId": choosedPollDataForNotificatoinEditId,
                    "type": pollMessage,
                    "message": pollMessagetext,
                    "currentDateTime": new Date().getTime()
                })
            };
            awsAPIUrlcommon(postData)
                .then((data) => {
                    if (data.success === "1") {
                        $(".loader").hide();
                        alert("Successfully Send Your Message");
                        cancelClickMessage()
                        getFullListData(siteId);
                    } else {
                        $(".loader").hide();
                        alert("Something went wrong");
                        cancelClickMessage()
                    }
                })
                .catch((error) => {
                    $(".loader").hide();
                    console.error("Error sending notification:", error);
                    alert("An error occurred while sending notification");
                });
        }
    }

    return (
        <div className="dashboard-doctorOuter">
            <Suspense fallback={<div></div>}>
                <section>
                    <Header />
                    <div className="loader"></div>
                    <div className="container mb-5">
                        <div>
                            {window.localStorage.getItem("isLogin") == "true" && window.localStorage.getItem("role") === "ROLE_ADMIN" ? (
                                <ul className="breadcrum-adminMangmnt">
                                    <li>
                                        <a href="/admin/dashboard">Dashboard</a>
                                    </li>

                                    <li>
                                        <a href="" className="current">
                                            Poll List
                                        </a>
                                    </li>
                                </ul>) : ""}
                        </div>
                        <div className="row-margin col-lg-4">
                            <label className="label-text-journal">
                                Site Details <span className="star-red-label">*</span>
                            </label>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-3 col-lg-4 div">
                                <input
                                    type="text"
                                    disabled={dropDownFlag}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    placeholder="Type to filter..."
                                    className="form-control"
                                />
                                {showList && inputValue && (
                                    <ul className="input-design-journal">
                                        {filteredData.map((item, index) => (
                                            <li className="py-1" onClick={() => handleChange(item)} key={index}>
                                                {item.siteName}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div className="col-md-2 my-2 my-md-0">
                                <input
                                    type="text"
                                    name="pollName"
                                    id="poll_name"
                                    placeholder="Poll Name"
                                    value={search}
                                    disabled={inputValue ? false : true}
                                    onMouseDown={handleSiteIdCheck}
                                    onBlur={handleInputChangeOnType}
                                    onChange={ 
                                        (e) =>  
                                            setSearch(e.target.value)} 
                                    className="form-control"
                                />
                                
                            </div>
                            <div  className="col-md-2 my-2 my-md-0"><button style={{ padding : '4px 25px'}} className="smssetng-svebtn mr-3"  onSubmit={handleInputChangeOnType}>Search</button></div>
                            {siteId &&
                                <div className="AddNewHspbtnDiv offset-lg-2 col-md-2 text-right">
                                    <button
                                        onClick={() => handleClickCreate({ data: "", siteId: siteId })}
                                        // to={{
                                        //     pathname: "/adminManagement/create-poll",
                                        //     state: {
                                        //         data: "", siteId: siteId
                                        //     },
                                        // }}
                                        // to={
                                        //   "/adminManagement/create-poll"}
                                        className="addnewhspbtn mr-3"
                                    > Add New Poll</button>
                                    {/* <a href="/adminManagement/create-poll" className="addnewhspbtn mr-3">
                                    Add New Poll
                                </a> */}
                                </div>}
                        </div>
                        <div className="HosptlMangemntTable">
                            {totalPageCount !== 0 && (
                                <div className="pagination-qualList pagination-qualListwidth text-right" id="paginationType">
                                    <Pagination
                                        activePage={currentPage}
                                        itemsCountPerPage={itemsPerPage}
                                        totalItemsCount={pollListDataPagination.length}
                                        pageRangeDisplayed={pageRange} // Adjust as needed
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            )}
                            <div className="table-responsive">
                                {pollListDataDuplicate.length ?
                                    <table className="table table-bordered-hsp">
                                        <thead>
                                            <tr>
                                                <th className="tablehead-hspmng">Sl No</th>
                                                <th className="tablehead-hspmng" style={{ width: "200px" }}>Poll Name</th>
                                                <th className="tablehead-hspmng">Description</th>
                                                <th className="tablehead-hspmng" style={{ width: "50px" }}>Status</th>
                                                <th className="tablehead-hspmng" style={{ width: "140px" }}>Poll Start Time</th>
                                                <th className="tablehead-hspmng" style={{ width: "140px" }}>Poll End Time</th>
                                                <th className="tablehead-hspmng" style={{ width: "50px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pollListDataDuplicate.map((data, i) => (
                                                <tr key={i}>
                                                    <td>{data.id}</td>
                                                    <td>{data.pollName}</td>
                                                    <td>{data.pollDescription}</td>
                                                    {!data.is_notification_send ?
                                                        <td><Switch
                                                            title="Status"
                                                            className="switch_case"
                                                            checked={data.isActive === 1}
                                                            onChange={(checked) => {
                                                                statusChanging(
                                                                    data,
                                                                    data.isActive === 1 ? false : true
                                                                );
                                                            }}></Switch></td> : <td>Cannot Change Status of Sent Notification</td>}
                                                    <td className="nowrap">{formatDate(data.pollStartDate)}</td>
                                                    <td className="nowrap">{formatDate(data.pollEndDate)}</td>
                                                    <td className="text-center">
                                                        <span
                                                            className=""
                                                            onClick={() => handleClickEdit({ data: data.pollId, siteId: siteId })}
                                                            style={{cursor:'pointer'}}
                                                        >
                                                            <img
                                                                src={Edit}
                                                                alt=""
                                                                width="18"
                                                                title="Edit"
                                                                className="img-fluid"
                                                            />
                                                        </span>
                                                        <span
                                                        
                                                            // to={{
                                                            //     pathname: "/adminManagement/poll-History",
                                                            //     state: { data: data.pollId, siteId: siteId },
                                                            // }}
                                                            // to={
                                                            onClick={() => handleClickHistory({ data: data.pollId, siteId: siteId })}
                                                            className="ml-2"
                                                            style={{cursor:'pointer'}}
                                                        >
                                                            <img
                                                                src={view}
                                                                alt=""
                                                                width="18"
                                                                title="View Poll Details"
                                                                className="img-fluid"
                                                            />
                                                        </span>

                                                        {!data.is_notification_send && data.isActive === 1 &&
                                                            <button
                                                                type="button"
                                                                className="formButton-save"
                                                                title="Send Notification"
                                                                onClick={(e) => sendNotification(data.pollId)}
                                                                style={{ fontSize: '12px', padding: '2px 10px' }}
                                                            >Send Notification</button>}

                                                        {data.is_notification_send && data.isActive === 1 &&
                                                            <button
                                                                type="button"
                                                                className="formButton-save"
                                                                title="Send Notification"
                                                                onClick={(e) => sendReminderNotificationToDoctors(data.pollId)}
                                                                style={{ fontSize: '12px', padding: '2px 10px' }}
                                                            >Send Reminder </button>}
                                                        <button
                                                            onClick={() => handleClickReport({ data: data.pollId, siteId: siteId })}
                                                            type="button"
                                                            className="formButton-save"
                                                            style={{ fontSize: '12px', padding: '2px 10px' }}
                                                        >
                                                            Poll Report
                                                        </button>
                                                        <button
                                                             className="formButton-save"
                                                             style={{ fontSize: '12px', padding: '2px 10px' }}
                                                            onClick={() => handleClickEditMessage(data)}
                                                        >
                                                            Customize
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* More table rows */}
                                        </tbody>
                                    </table>
                                    : <div className="col-md-12 mt-2 text-center">No DataFound</div>}
                                {totalPageCount !== 0 && (
                                    <div
                                        className="pagination-qualList pagination-qualListwidth text-right"
                                        id="paginationType"
                                    >
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={pollListDataPagination.length}
                                            pageRangeDisplayed={pageRange} // Adjust as needed
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </section>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <section>
                    <Footer />
                </section>
            </Suspense>
            <div className="poll_popupdiv" style={{ display: 'none' }}>
                <div className="videocall_div poll_popup" id="shedulePopup">
                    <div className="videocall_header">Message</div>
                    <div className="videocall_body flex-column">
                        <select className="form-control mb-3" value={pollMessage} onChange={(e) => pollNotificationMessage(e)}>
                            <option value="">Select Poll Message</option>
                            <option value="pollNotificationMessage">Poll Notification </option>
                            <option value="pollReminderNotificationMessage"> Poll Reminder Notification</option>
                            <option value="pollSuccessMessage"> Poll Success </option>
                        </select>
                        <textarea className="form-control" value={pollMessagetext} onChange={handleInputChangeOnTypeMessage}></textarea>
                    </div>
                    <div className="videocall_footer">
                        <button
                            type="button"
                            className="bttn_ok"
                            onClick={(e) => saveClickMessage(e)}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="bttn_ok bg-secondary"
                            onClick={(e) => cancelClickMessage(e)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PollList;