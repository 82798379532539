import React, { useState, useEffect } from "react";
import { apiCalling } from "../../apiService";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import $ from "jquery";
import Select from "react-select";
import Pagination from "react-js-pagination";
import AnalyticsListAlert from "./analyticsListAlert";
import AnalyticsListevent from "./analyticsListEvents";
import AnalyticsListNewsFeedView from "./analyticsListNewsFeedView";
import AnalyticsListNewsFeedDownload from "./analyticsListNewsFeedDownload";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function AnalyticsList() {
    const [eventViewListDataAlert, setEventViewListDataAlert] = useState([]);
    const [eventViewListDataEvents, setEventViewListDataEvents] = useState([]);
    const [newsFeedListData, setNewsFeedListData] = useState([]);
    const [newsFeedDownloadData, setNewsFeedDownloadData] = useState([]);
    const [hospitalArray, setHospitalArray] = useState([]);
    const [eventAlertCount, setEventAlertCount] = useState("");
    const [eventViewListDataEventsCount, setEventViewListDataEventsCount] = useState("");
    const [newsFeedCount, setNewsFeedCount] = useState("");
    const [newsFeedDownloadcount, setNewsFeedDownloadCount] = useState("");
    const [offset, setOffset] = useState(0);
    const [activepage, setActivePage] = useState(1);

    useEffect(() => {
        getHospitalDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    const getHospitalDetails = () => {
        //$("#analisePopup").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                "functionName": "analyticsList",
                "siteId": $("#txtHospitalIdNew").val() === "" ? "" : $("#txtHospitalIdNew").val(),
                "max": "10",
                "offset": offset,
            }),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            // console.log(data,'qqqqqqqqqqqqqq');
            $("#analisePopup").hide();
            if (data.success === "1") {
                $(".loader").hide();
                setEventViewListDataAlert(data.result[0].alertList);
                setEventViewListDataEvents(data.result[0].eventViewList);
                setNewsFeedListData(data.result[0].newsFeedList);
                setNewsFeedDownloadData(data.result[0].newsFeedDownload);
                setEventAlertCount(data.result[0].alertListCount);
                setEventViewListDataEventsCount(data.result[0].eventViewCount);
                setNewsFeedCount(data.result[0].newsFeedListCount);
                setNewsFeedDownloadCount(data.result[0].newsFeedDownloadCount);
            } else {
                alert(data.errorMessage);
                $(".loader").hide();
            }
        }).catch((error) => {
            $(".loader").hide();
            console.error("Error sending notification:", error);
            // alert("An error occurred while sending notification");
        });
    };

    const setHospitalId = (hospitalId, hospitalName) => {
        setHospitalArray([]);
        $('#analisePopup').hide();
        $("#cmbHospitalName").val(hospitalName);
        $("#txtHospitalIdNew").val(hospitalId);
        getHospitalDetails();
    };

    const SettingHospitalIds = () => {
        if ($("#cmbHospitalName").val() === "") {
            $("#listOfsite").attr("hidden", true);
            $("#txtHospitalIdNew").val("");
            setActivePage(1);
            setOffset(0);
            getHospitalDetails();
        }
        if ($("#cmbHospitalName").val().length > 2) {
            $("#listOfsite").attr("hidden", false);
            const apiJson = {
                method: "POST",
                body: JSON.stringify({
                    "functionName": "addOrEditSite",
                    "cmbSelect": "siteName",
                    "searchKeyWord": $("#cmbHospitalName").val(),
                    "max": "",
                    "Offset": ""
                }),
            };
            apiCalling(apiJson).then((data) => {
                // console.log(data,'aaaaaaaaaa');
                if (data.success === "1") {
                    setHospitalArray(data.siteData);
                }
            }).catch((error) => {
                $(".loader").hide();
                console.error("Error sending notification:", error);
                // alert("An error occurred while sending notification");
            });
        }
    };

    const TabHandler = () => {
        setActivePage(1);
        setOffset(0);
        getHospitalDetails();
    };

    return (
        <div className="purpleWrap bg-clr-wthHead">
            <section>
                <Header />
                <div className="loader"></div>
            </section>
            <div className="loader"></div>
            <div className="container mb-5">
                <div>
                    <ul className="breadcrum-adminMangmnt">
                        <li>
                            <a href="/admin/dashboardAdmin">Dashboard</a>
                        </li>
                        <li>
                            <a className="current">Analytics</a>
                        </li>
                    </ul>
                </div>
                <div className="head-hospMangmntList mb-0"> Analytics</div>
                <div className="addHspPage-Wrapper">
                    <Tabs>
                        <TabList className="mt-3 mb-0 pl-0 reactTab-ul" onClick={(e) => TabHandler()}>
                            <Tab>Alert Details</Tab>
                            <Tab>Event Details</Tab>
                            <Tab>Newsfeed View Details</Tab>
                            <Tab>Newsfeed Download Details</Tab>
                        </TabList>
                        <div className="paginationSection orderm-paginationSection pagination-HspList w-100"></div>
                        <div className="tableHeader-HspMangemnt">
                            <div className="selectField-HspMangmnt"></div>
                            <input
                                name="cmbHospitalName"
                                type="text"
                                autoComplete="off"
                                id="cmbHospitalName"
                                placeholder="Site Name"
                                className="hspInfofieldinput hspinput-mobdoctr"
                                onKeyUp={(e) => SettingHospitalIds(e)}
                            ></input>
                            <input
                                type="text"
                                id="txtHospitalIdNew"
                                name="txtHospitalIdNew"
                                hidden
                                className="hspInfofieldinput dctrofce-inputhspid"
                                placeholder="Hospital Id"
                            />
                            <ul className="auto-complete-wrapper" hidden id="listOfsite">
                                {hospitalArray.map((timeObj, i) => {
                                    return (
                                        <li
                                            className="hosptalList"
                                            autocomplete="off"
                                            onClick={() => setHospitalId(timeObj.siteId, timeObj.siteName)}
                                        >
                                            {timeObj.siteName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div>
                            <TabPanel>
                                <AnalyticsListAlert
                                    eventViewListDataAlert={eventViewListDataAlert}
                                    eventAlertCount={eventAlertCount}
                                    activepage={activepage}
                                    setPagination={(offset) => {
                                        setActivePage(offset);
                                        setOffset((offset - 1) * 10);
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AnalyticsListevent
                                    eventViewListDataEvents={eventViewListDataEvents
                                    }
                                    eventViewListDataEventsCount={eventViewListDataEventsCount}
                                    activepage={activepage}
                                    setPagination={(offset) => {
                                        setActivePage(offset);
                                        setOffset((offset - 1) * 10);
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AnalyticsListNewsFeedView
                                    newsFeedListData={newsFeedListData}
                                    newsFeedCount={newsFeedCount}
                                    activepage={activepage}
                                    setPagination={(offset) => {
                                        setActivePage(offset);
                                        setOffset((offset - 1) * 10);
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AnalyticsListNewsFeedDownload
                                    newsFeedDownloadData={newsFeedDownloadData}
                                    newsFeedDownloadcount={newsFeedDownloadcount}
                                    activepage={activepage}
                                    setPagination={(offset) => {
                                        setActivePage(offset);
                                        setOffset((offset - 1) * 10);
                                    }}
                                />
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AnalyticsList;
