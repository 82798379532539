import React, { useEffect, useState } from "react";

import "./index.css";
// import RelianceComponent from './Route';

//import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation, Navigate, Red,
  redirect, useNavigate
} from "react-router-dom";
import Home from "../src/home/home";
import About from "./home/about";
import CaseStudy from "./home/cases-study-detail";
import CaseStudy1 from "./home/case-study-detail-2";
import CaseStudy3 from "./home/case-study-detail-3";
import CaseStudy4 from "./home/case-study-detail-4";

import Contact from "./home/contactus";
import PrivacyPolicy from "./home/privacyPolicy";
import TermsAndConditions from "./home/termsAndConditions";
import FreeCmsOne from "./promotions/freecms1";
import FreeCmsTwo from "./promotions/freecms2";
import DoctorDetail from "./home/doctor-detail";
import DoctorListMain from "./home/doctor-list-main";
import email from "./patient/email";
import EmailConfirm from "./patient/emailconfirm";
import EmailSuccess from "./patient/emailsuccess";
import Textchat from "./patient/textchat";
import TextchatSuccess from "./patient/textchatsuccess";
import Bookappointment from "./patient/bookappointment";
import BookappointmentSuccess from "./patient/bookappointmentsuccess";
import Chatmessage from "./patient/chatmessage";
import VideocallList from "./patient/videocalllist";
import ChatmessageGroup from "./patient/chatmessagegroup";
import Videocall from "./patient/videocall";
import VideochatSuccess from "./patient/videochatsuccess";
import Appointmentlist from "./patient/appointmentlist";
import Inbox from "./patient/inbox";
import Send from "./patient/send";
import Messgedetail from "./patient/messgedetail";
import Profile from "./patient/profile";
import ProfileEdit from "./patient/profile-edit";
import Dashboard from "./doctor/dashboard";
import ChangePassword from "./doctor/changepassword";
import WorkTime from "./doctor/worktime";
import Doctorprofile from "./doctor/doctorprofile";
import Docservices from "./doctor/docservices";
import VideoSchedule from "./doctor/videoSchedule";
import Healtharticle from "./doctor/healtharticle";
import Healthblog from "./doctor/healthblog";
import HealthRecord from "./patient/health-record";
import History from "./patient/history";
import DoctorVideocall from "./doctor/videocall";
import DoctorInbox from "./doctor/doctorinbox";
import DoctorSend from "./doctor/doctorsend";
import DoctorMessgedetail from "./doctor/messgedetail";
import DoctorChatmessage from "./doctor/chatmessage";
import DoctorChatmessageGroup from "./doctor/chatmessagegroup";
import DoctorChatHistory from "./doctor/chat-history";
import OldChatMessage from "./patient/archivedMessageChats";
//import video from './home/video';
//import email from './home/email';
//import callDoctor from './home/callDoctor';
//import bookappointment from './home/bookappointment';
//import doctorDetails from './home/doctorDetails';
/*** pharmacy app screens***/

import Splash from "./pharmacyApp/splash";
import Network from "./pharmacyApp/network";
import DoctorNetwork from "./pharmacyApp/doctor-network";
import DoctorInvitation from "./pharmacyApp/doctor-invitation";
import AdminLogin from "./Login/adminLogin";
import AdminDashboard from "./adminDashboard/dashboard";
import DashboardAdmin from "./adminDashboard/dashboardAdmin";
import DcadminDashboard from "./adminDashboard/dcAdmindashboard";
import HospitalDoctorSchedule from "./adminManagement/hospitalManagementList/hospitalDoctorSchedule";
import HospitalMangementList from "./adminManagement/hospitalManagementList/hospitalManagementList";
import AnalyticsList from "./adminManagement/analyticsManagement/analyticsList";
import ImageUpload from "./adminManagement/imageUpload/imageUpload";
import SmsSettings from "./adminManagement/smsSettings/sms-settings";
import AddnewHsp from "./adminManagement/hospitalManagementList/addnewHosp";
import HospitalInfomation from "./adminManagement/hospitalManagementList/hospitalInformation";
import HospitalworkDetails from "./adminManagement/hospitalManagementList/hospitalworkDetails";
import HospitalSpecialisation from "./adminManagement/hospitalManagementList/hospitalSpecialisation";
import SuperAdmin from "./adminManagement/hospitalManagementList/superAdmin";
import BankAccountdetails from "./adminManagement/hospitalManagementList/bankAccountdetails";
import ConsultingTime from "./adminManagement/hospitalManagementList/consultingTime";
import VisitingTime from "./adminManagement/hospitalManagementList/visitingTime";
import Servicedetails from "./adminManagement/hospitalManagementList/servicedetails";
import Addimages from "./adminManagement/hospitalManagementList/addimages";
import HospitalRazrPay from "./adminManagement/hospitalManagementList/hospitalRazorPay";
import HospitalAdmindetails from "./adminManagement/hospitalManagementList/hospitalAdmindetails";
import Seo from "./adminManagement/hospitalManagementList/seo";
import LogoUpload from "./adminManagement/hospitalManagementList/logoUpload";
import Photo from "./adminManagement/hospitalManagementList/photo";
import LinkHsp from "./adminManagement/hospitalManagementList/link";
import Procedurelist from "./adminManagement/hospitalManagementList/procedurelist";
import Addnewprocedurelist from "./adminManagement/hospitalManagementList/addnewprocedurelist";
import DoctorManagement from "./adminManagement/doctorManagementList/doctorManagement";
import AddDoctor from "./adminManagement/doctorManagementList/addDoctor";
import Doctorinformation from "./adminManagement/doctorManagementList/doctorinformation";
import DoctorOffcicehours from "./adminManagement/doctorManagementList/doctorOffcicehours";
import DoctorSpecialisation from "./adminManagement/doctorManagementList/doctorSpecialisation";
import EducationDetails from "./adminManagement/doctorManagementList/educationDetails";
import Achievementsdetails from "./adminManagement/doctorManagementList/achievementsdetails";
import Proceduredetails from "./adminManagement/doctorManagementList/proceduredetails";
import OfficialInformation from "./adminManagement/doctorManagementList/officialInformation";
import SearchOffceService from "./adminManagement/doctorManagementList/searchoffceservice";
import Doctorseo from "./adminManagement/doctorManagementList/doctorseo";
import DoctorPhoto from "./adminManagement/doctorManagementList/doctorPhoto";
import AddProcedure from "./adminManagement/hospitalManagementList/addProcedure";

//! Old SiteSettings Routes
// import siteSettings from "./adminManagement/siteSettings/siteSettings";
// import siteSettingsAddEdit from "./adminManagement/siteSettings/siteSettings-AddEdit";
import siteSettingsSiteModule from "./adminManagement/siteSettings/siteSettingsSiteModule";
import siteSettingsSiteModuleByDoctor from "./adminManagement/siteSettings/siteSettingsSiteModuleByDoctor";
import siteSettingsSiteModuleByHospital from "./adminManagement/siteSettings/siteSettingsSiteModuleByHospital";

//! New SiteSettingsRoutes
import SiteSettings from "./adminManagement/SiteSettingsv2/siteSettings";
import SiteSettingsAddEdit from "./adminManagement/SiteSettingsv2/SiteSettings-AddEdit";

import medicineBranch from "./adminManagement/medicineBranch/medicineBranch";
import Specialisation from "./adminManagement/medicineBranch/Specialisation/Specialisation";

import DoctorPanel from "./adminManagement/messaging/doctorPanenl/doctorPanenl";
import Messaging from "./adminManagement/messaging/messaging";
import Journalisting from "./adminManagement/journalDashboard/journalListing";
import AddJournal from "./adminManagement/journalDashboard/addJournal";
import DoctorWorkdashboard from "./adminDashboard/doctorWorkdashboard";
import Qualification from "./adminManagement/doctorWorkdashboardList/qualification";
import Language from "./adminManagement/doctorWorkdashboardList/language";
import healthInformation from "./adminManagement/healthInformation/healthInformation";
import helthInfo from "./adminManagement/healthInformation/healthInfo";
import Pharmadashboard from "./adminDashboard/pharmaDashboard";
import RepApprovalList from "./adminManagement/pharmadashboardList/repApprovalList";
import IdaStoresList from "./adminManagement/pharmadashboardList/idaStoresList";
import RepAdd from "./adminManagement/pharmadashboardList/addRep";
import MobileappDashboard from "./adminDashboard/mobileappdashboard";
import HospitalBannerdetails from "./adminManagement/mobileAppdashboard/hospitalbannerdetails";
import Addbanner from "./adminManagement/mobileAppdashboard/addBanner";
import ApplicationsList from "./adminManagement/mobileAppdashboard/applicationsList";
import ApplicationsEditSave from "./adminManagement/mobileAppdashboard/applicationsEditSave";
import productDashboard from "./adminManagement/product/productDashboard";
import hospitalProducts from "./adminManagement/product/hospitalProducts/hospitalProducts";
import manageLocations from "./adminManagement/manageLocation/manageLocation";
import countries from "./adminManagement/manageLocation/manageLocations/countries/countries";
import cities from "./adminManagement/manageLocation/manageLocations/cities/cities";
import states from "./adminManagement/manageLocation/manageLocations/states/states";
import subcities from "./adminManagement/manageLocation/manageLocations/subcities/subcities";

import relianceIndex from "./reliance/reliance-Index";
import relianceDashboard from "./reliance/reliance-dashboard";
import RelianceLogin from "./reliance/reliance-login";
import textChatReports from "./reliance/textChatReports";
import videoaudioReports from "./reliance/videoaudioReports";
import Addapproval from "./adminManagement/pharmadashboardList/adApproval";
import AddapprovalEdit from "./adminManagement/pharmadashboardList/adApprovalEdit";
import PharmaCompayList from "./adminManagement/pharmadashboardList/pharmaCompanyList";
import PharmaAddnewCompany from "./adminManagement/pharmadashboardList/pharmaAddnewCompany";
import PharmaEditnewCompany from "./adminManagement/pharmadashboardList/pharmaEditnewCompany";
import DoctorManagementDashboard from "./adminDashboard/doctorManagementDashboard";
import SimpleDoctorRegistration from "./adminManagement/doctorManagementList/simpleDoctorRegistration/simpleDoctorRegistration";
import ContactUsReports from "./adminManagement/adminReports/contactUsReports";
import ReportsDashboard from "./adminDashboard/reportsDashboard";
import PatientReport from "./adminDashboard/patientReport";
import DoctorRegistrationReportThrourhApp from "./adminDashboard/doctorRegistrationReportThroughApp";
import AddEditIdaStore from "./adminManagement/pharmadashboardList/addEditIdaStore";
import GenerateQRcodeIda from "./adminManagement/pharmadashboardList/generateQRcodeIda";
import Feedback from "./adminManagement/feedback";
import UrlRedirect from "./adminManagement/urlRedirect";
import AppPrivacyPolicy from "./phApp/appPrivacyPolicy";
import digitalBranding from "./home/digital-branding";
import digitalMarketing from "./home/digital-marketing";
import localPresenceManagement from "./home/local-presence-management";
import PollList from "./adminDashboard/PollList";
import PollHistory from "./adminDashboard/pollHistory";
import PollReport from "./adminDashboard/pollReport";
import CreatePoll from "./adminDashboard/createPoll";
import DoctorLoginDetails from "./adminDashboard/doctorLoginReport";
import Resources from "./home/resources";
import News from "./home/news";
import Blogs from "./home/blogs";
import NewsDeatils from "./home/news-letter-details";
import BlogDeatils from "./home/blog-details";
import PdtDoctors from "./home/product-for-doctors";
import PdtPharmaCompany from "./home/product-for-pharma-company";
import PdtPharmacy from "./home/product-for-pharmacy";
import Insightx from "./home/insightx";
import Thankyou from "./home/thank-you";
import RhaDashboard from "./reliance/rh-dashboard";
import RhFileupload from "./reliance/rh-file-upload";
import Medical from "./home/medical";
import Dental from "./home/dental";
function App() {
  const [isLoginPurple, setIsLoginPurple] = useState(
    window.localStorage.getItem("isLogin") === "true"
  );
  const relianceIsAuthencated =
    window.localStorage?.getItem("isLogin") === "true" ? true : false;

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/thankyou" Component={Thankyou} />
          <Route exact path="/medical" Component={Medical} />
          <Route exact path="/dental" Component={Dental} />
          <Route exact path="/products-for-doctors" Component={PdtDoctors} />
          <Route exact path="/products-for-pharma-company" Component={PdtPharmaCompany} />
          <Route exact path="/products-for-pharmacy" Component={PdtPharmacy} />
          <Route exact path="/Insightx" Component={Insightx} />
          <Route exact path="/digital-branding" Component={digitalBranding} />
          <Route exact path="/digital-marketing" Component={digitalMarketing} />
          <Route
            exact
            path="/local-presence-management"
            Component={localPresenceManagement}
          />
          <Route exact path="/resources" Component={Resources} />
          <Route exact path="/news" Component={News} />
          <Route exact path="/blogs" Component={Blogs} />
          <Route exact path="/blog-details" Component={BlogDeatils} />
          <Route exact path="/news-details" Component={NewsDeatils} />
          <Route exact path="/about" Component={About} />
          <Route exact path="/case-study" Component={CaseStudy} />
          <Route
            exact
            path="/case-study-prans-patient"
            Component={CaseStudy1}
          />
          <Route exact path="/case-study-ida" Component={CaseStudy3} />
          <Route exact path="/case-study-medical-rep" Component={CaseStudy4} />
          <Route path="/contactus" Component={Contact} />
          <Route path="/privacy-policy" Component={PrivacyPolicy} />
          <Route path="/app/privacyPolicy" Component={AppPrivacyPolicy} />
          <Route path="/terms-conditions" Component={TermsAndConditions} />
          <Route path="/promotions/freecms1" Component={FreeCmsOne} />
          <Route path="/promotions/freecms2" Component={FreeCmsTwo} />
          <Route path="/home/doctor-detail" Component={DoctorDetail} />
          <Route path="/home/doctor-list-main" Component={DoctorListMain} />
          <Route path="/patient/email" Component={email} />
          <Route path="/patient/emailconfirm" Component={EmailConfirm} />
          <Route path="/patient/emailsuccess" Component={EmailSuccess} />
          <Route path="/patient/textchatpayment" Component={Textchat} />
          <Route path="/patient/textchatsuccess" Component={TextchatSuccess} />
          <Route path="/patient/bookappointment" Component={Bookappointment} />
          <Route
            path="/patient/bookappointmentsuccess"
            Component={BookappointmentSuccess}
          />
          <Route path="/chatmessage" Component={Chatmessage} />
          <Route path="/archivedMessageChats" Component={OldChatMessage} />
          <Route path="/patient/videocalllist" Component={VideocallList} />
          <Route path="/chatmessagegroup" Component={ChatmessageGroup} />
          <Route path="/patient/videocall" Component={Videocall} />
          <Route
            path="/patient/videochatsuccess"
            Component={VideochatSuccess}
          />
         
          <Route path="/splash/:splashId?/:networkId" Component={Splash} />
          <Route path="/network/:splashId" Component={Network} />
          <Route path="/doctorNetwork" Component={DoctorNetwork} />
          <Route path="/doctorInvitation" Component={DoctorInvitation} />
          <Route exact path="/login" Component={AdminLogin} />
          
          <Route path="/5a6uk96sph/:token" Component={Feedback} />
          <Route path="/:token" Component={UrlRedirect} />
          {/* <Route element={<PrivateRoutes />}/> */}

          {isLoginPurple ? (
            <>
              <Route
                path="/adminManagement/SiteSettings"
                Component={SiteSettings}
              />
               <Route path="/patient/appointmentlist" Component={Appointmentlist} />
          <Route path="/patient/inbox" Component={Inbox} />
          <Route path="/patient/send" Component={Send} />
          <Route path="/patient/messgedetail" Component={Messgedetail} />
          <Route path="/patient/profile" Component={Profile} />
          <Route path="/patient/profile-edit" Component={ProfileEdit} />
          <Route path="/doctor/dashboard" Component={Dashboard} />
          <Route path="/doctor/changepassword" Component={ChangePassword} />
          <Route path="/doctor/worktime" Component={WorkTime} />
          <Route path="/doctor/doctorprofile" Component={Doctorprofile} />
          <Route path="/doctor/docservices" Component={Docservices} />
          <Route path="/doctor/videoSchedule" Component={VideoSchedule} />
          <Route path="/doctor/healtharticle" Component={Healtharticle} />
          <Route path="/doctor/healthblog" Component={Healthblog} />
          <Route path="/patient/health-record" Component={HealthRecord} />
          <Route path="/patient/history" Component={History} />
          <Route path="/doctor/videocall" Component={DoctorVideocall} />
          <Route path="/doctor/doctorinbox" Component={DoctorInbox} />
          <Route path="/doctor/doctorsend" Component={DoctorSend} />
          <Route path="/doctor/messgedetail" Component={DoctorMessgedetail} />
          <Route path="/doctor/chatmessage" Component={DoctorChatmessage} />
          <Route
            path="/doctor/chatmessagegroup"
            Component={DoctorChatmessageGroup}
          />
          <Route path="/doctor/chat-history" Component={DoctorChatHistory} />
              <Route
                path="/adminManagement/SiteSettings-AddEdit"
                Component={SiteSettingsAddEdit}
              />
              <Route path="/admin/dashboard" Component={AdminDashboard} />
          <Route path="/admin/dashboardAdmin" Component={DashboardAdmin} />
          <Route path="/admin/dcAdmindashboard" Component={DcadminDashboard} />
              <Route
                path="/adminManagement/SiteSettings-SiteModule"
                Component={siteSettingsSiteModule}
              />
              <Route
                path="/adminManagement/SiteSettings-SiteModuleByHospital"
                Component={siteSettingsSiteModuleByHospital}
              />
              <Route
                path="/adminManagement/SiteSettings-SiteModuleByDoctor"
                Component={siteSettingsSiteModuleByDoctor}
              />
              <Route
                path="/adminManagement/medicineBranch"
                Component={medicineBranch}
              />
              <Route
                path="/adminManagement/specialisation"
                Component={Specialisation}
              />
              <Route
                path="/adminManagement/analyticsManagement/analyticsList"
                Component={AnalyticsList}
              />
              <Route
                path="/adminManagement/imageUpload"
                Component={ImageUpload}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalDoctorSchedule/:hospitalId"
                Component={HospitalDoctorSchedule}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalManagementList"
                Component={HospitalMangementList}
              />
              <Route
                path="/adminManagement/smsSettings/sms-settings"
                Component={SmsSettings}
              />
              <Route
                path="/adminManagement/hospitalManagementList/addnewHosp"
                Component={AddnewHsp}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalInformation"
                Component={HospitalInfomation}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalworkDetails"
                Component={HospitalworkDetails}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalSpecialisation"
                Component={HospitalSpecialisation}
              />
              <Route
                path="/adminManagement/hospitalManagementList/superAdmin"
                Component={SuperAdmin}
              />
              <Route
                path="/adminManagement/hospitalManagementList/bankAccountdetails"
                Component={BankAccountdetails}
              />
              <Route
                path="/adminManagement/hospitalManagementList/consultingTime"
                Component={ConsultingTime}
              />
              <Route
                path="/adminManagement/hospitalManagementList/visitingTime"
                Component={VisitingTime}
              />
              <Route
                path="/adminManagement/hospitalManagementList/servicedetails"
                Component={Servicedetails}
              />
              <Route
                path="/adminManagement/hospitalManagementList/addimages/:hospitalId"
                Component={Addimages}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalRazrPay/:hospitalId"
                Component={HospitalRazrPay}
              />
              <Route
                path="/adminManagement/hospitalManagementList/hospitalAdmindetails"
                Component={HospitalAdmindetails}
              />
              <Route
                path="/adminManagement/hospitalManagementList/seo"
                Component={Seo}
              />
              <Route
                path="/adminManagement/hospitalManagementList/logoUpload"
                Component={LogoUpload}
              />
              <Route
                path="/adminManagement/hospitalManagementList/photo"
                Component={Photo}
              />
              <Route
                path="/adminManagement/hospitalManagementList/link"
                Component={LinkHsp}
              />
              <Route
                path="/adminManagement/hospitalManagementList/procedurelist"
                Component={Procedurelist}
              />
              <Route
                path="/adminManagement/hospitalManagementList/addnewprocedurelist"
                Component={Addnewprocedurelist}
              />
              {/* <Route
              path="/adminManagement/doctorManagementList/doctorManagement"
              element={doctorManagement}
            /> */}
              <Route
                path="/adminManagement/doctorManagementList/doctorManagement"
                element={<DoctorManagement />}
              />
              <Route
                path="/adminManagement/doctorManagementList/addDoctor"
                element={<AddDoctor />}
              />
              <Route
                path="/adminManagement/doctorManagementList/doctorinformation"
                Component={Doctorinformation}
              />
              <Route
                path="/adminManagement/doctorManagementList/doctorOffcicehours"
                Component={DoctorOffcicehours}
              />
              <Route
                path="/adminManagement/doctorManagementList/doctorSpecialisation"
                Component={DoctorSpecialisation}
              />
              <Route
                path="/adminManagement/doctorManagementList/educationDetails"
                Component={EducationDetails}
              />
              <Route
                path="/adminManagement/doctorManagementList/achievementsdetails"
                Component={Achievementsdetails}
              />
              <Route
                path="/adminManagement/doctorManagementList/proceduredetails"
                Component={Proceduredetails}
              />
              <Route
                path="/adminManagement/doctorManagementList/officialInformation"
                Component={OfficialInformation}
              />
              <Route
                path="/adminManagement/doctorManagementList/searchoffceservice"
                Component={SearchOffceService}
              />
              <Route
                path="/adminManagement/doctorManagementList/doctorseo"
                Component={Doctorseo}
              />
              <Route
                path="/adminManagement/doctorManagementList/doctorPhoto"
                Component={DoctorPhoto}
              />
              <Route
                path="/adminManagement/doctorManagementList/doctorManagementDashboard"
                Component={DoctorManagementDashboard}
              />
              <Route
                path="/adminManagement/doctorManagementList/simpleDoctorRegistrationForm"
                Component={SimpleDoctorRegistration}
              />
              <Route
                path="/adminManagement/hospitalManagementList/addProcedure"
                Component={AddProcedure}
              />

              <Route
                path="/adminManagement/doctorpanenl"
                Component={DoctorPanel}
              />
              <Route path="/adminManagement/messaging" Component={Messaging} />
              <Route
                path="/adminManagement/journalDashboard/journalListing"
                Component={Journalisting}
              />
              <Route
                path="/adminManagement/journalDashboard/addJournal/:journalId?"
                Component={AddJournal}
              />
              <Route
                path="/adminDashboard/doctorWorkdashboard"
                Component={DoctorWorkdashboard}
              />
              <Route
                path="/adminManagement/doctorWorkdashboardList/qualification"
                Component={Qualification}
              />
              <Route
                path="/adminManagement/doctorWorkdashboardList/language"
                Component={Language}
              />
              <Route
                path="/adminManagement/healthInformation"
                Component={healthInformation}
              />
              <Route path="/adminManagement/healthInfo" Component={helthInfo} />
              <Route
                path="/adminDashboard/pharmaDashboard"
                Component={Pharmadashboard}
              />
              <Route
                path="/adminManagement/pharmadashboardList/repApprovalList"
                Component={RepApprovalList}
              />
              <Route
                path="/adminManagement/pharmadashboardList/idaStoresList"
                Component={IdaStoresList}
              />
              <Route
                path="/adminManagement/pharmadashboardList/addEditIdaStore/:storeId?"
                Component={AddEditIdaStore}
              />
              <Route
                path="/adminManagement/pharmadashboardList/generateQRcodeIda/:storeId?"
                Component={GenerateQRcodeIda}
              />
              <Route
                path="/adminManagement/pharmadashboardList/adApproval"
                Component={Addapproval}
              />
              <Route
                path="/adminManagement/pharmadashboardList/adApprovalEdit/:pharmaRepAdsId"
                Component={AddapprovalEdit}
              />
              <Route
                path="/adminManagement/pharmadashboardList/pharmaCompanyList"
                Component={PharmaCompayList}
              />
              <Route
                path="/adminManagement/pharmadashboardList/pharmaAddnewCompany"
                Component={PharmaAddnewCompany}
              />
              <Route
                path="/adminManagement/pharmadashboardList/pharmaEditnewCompany/:companyId"
                Component={PharmaEditnewCompany}
              />
              {/* <Route path="./adminManagement/pharmadashboardList/adApproval" Component={Addapproval} /> */}
              <Route
                path="/adminManagement/pharmadashboardList/addRep/:repId?"
                Component={RepAdd}
              />
              <Route
                path="/adminDashboard/mobileappdashboard"
                Component={MobileappDashboard}
              />
              <Route
                path="/adminManagement/mobileAppdashboard/hospitalbannerdetails"
                Component={HospitalBannerdetails}
              />
              <Route
                path="/adminManagement/mobileAppdashboard/addBanner/:hospitalbannerId?"
                Component={Addbanner}
              />
              <Route
                path="/adminManagement/mobileAppdashboard/applications"
                Component={ApplicationsList}
              />
              <Route
                path="/adminManagement/mobileAppdashboard/applicationsDetailEditSave"
                Component={ApplicationsEditSave}
              />
              <Route
                path="/adminManagement/productDashboard"
                Component={productDashboard}
              />
              <Route
                path="/adminManagement/hospitalProducts"
                Component={hospitalProducts}
              />
              <Route
                path="/adminManagement/manageLocations"
                Component={manageLocations}
              />
              <Route path="/adminManagement/countries" Component={countries} />
              <Route path="/adminManagement/cities" Component={cities} />
              <Route path="/adminManagement/states" Component={states} />
              <Route path="/adminManagement/subcities" Component={subcities} />
              <Route
                path="/adminManagement/reports"
                Component={ReportsDashboard}
              />
              <Route
                path="/adminManagement/patientReports"
                Component={PatientReport}
              />
              <Route
                path="/adminManagement/doctorRegistrationReportThroughApp"
                Component={DoctorRegistrationReportThrourhApp}
              />
              <Route
                path="adminManagement/reports/contactUsReports"
                Component={ContactUsReports}
              />
              <Route path="/adminManagement/pollList" Component={PollList} />
              <Route
                path="/adminManagement/poll-History"
                Component={PollHistory}
              />
              <Route
                path="/adminManagement/poll-report"
                Component={PollReport}
              />
              <Route
                path="/adminManagement/create-poll"
                Component={CreatePoll}
              />
              <Route
                path="/adminManagement/doctorLoginReport"
                Component={DoctorLoginDetails}
              />

            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" Component={Home} />
            </>
          )}

          {/* <Route path="/reliance" Component={relianceIndex} /> */}
          <Route path="/reliance/login" Component={RelianceLogin} />
          <Route
            path="/medical"
            Component={Medical}
          />
          {relianceIsAuthencated ? (
            <>
              <Route path="/reliance/dashboard" Component={relianceDashboard} />
              <Route
                path="/reliance/videoaudioReports/:groupHospitalId"
                Component={videoaudioReports}
              />
              <Route
                path="/reliance/textChatReports/:groupHospitalId"
                Component={textChatReports}
              />
              <Route path="/reliance/rha-fileupload" Component={RhFileupload} />
              <Route path="/reliance/rha-dashboard/" Component={RhaDashboard} />
            </>
          ) : (
            <></>
          )}
        </Routes>
      </Router>
    </>
  );
}
export default App;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

// function Route({ Component: Component, ...rest }) {
//   let authed =
//     window.localStorage.getItem("isLogin") == "true" ? true : false;
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         authed === true ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{ pathname: "/login", state: { from: props.location } }}
//           />
//         )
//       }
//     />
//   );
// }

// function ReliancePrivateRoute({ Component: Component, ...rest }) {
//   let authed =
//     window.localStorage.getItem("isLogin") == "true" ? true : false;
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         authed === true ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/reliance/login",
//               state: { from: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }
