import React, { useEffect, useState, forwardRef, useImperativeHandle, Suspense } from 'react';
import AddImg from '../../image/icons/addbtn.png';
import Closebtn from '../../image/icons/close-sm.png';
import { apiCalling } from '../../apiService';
import OfficeTime from '../doctorManagementList/doctorOfficeTime';

const DoctorOfficeHours = forwardRef((props, ref) => {
  const [hospitalData, setHospitalData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [subCityArray, setSubCityArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [countryArray, setCountryArray] = useState([]);
  const [stateDataCopy, setStateDataCopy] = useState([]);
  const [subCityArrayCopy, setSubCityArrayCopy] = useState([]);
  const [cityArrayCopy, setCityArrayCopy] = useState([]);
  const [cityValue, setCityValue] = useState("");
  const [subCitySelect, setSubCitySelect] = useState("");
  const [countryArrayCopy, setCountryArrayCopy] = useState([]);
  const [officeHoursDetails, setOfficeHoursDetails] = useState([]);
  const [hospitalArray, setHospitalArray] = useState([]);
  useEffect(() => {
    if (props.doctorData.length) {
      dataLoad();
    }
  }, [props.doctorData]);
  const dataLoad = () => {
    setOfficeHoursDetails(props.doctorOfficeHours || []);
    if (props.doctorData.length > 0) {
      const doctor = props.doctorData[0];
      document.getElementById('cmbState').value = doctor.state;
      document.getElementById('txtEmail').value = doctor.email;
      document.getElementById('txtAddress').value = doctor.address;
      document.getElementById('cmbCountry').value = doctor.country || "India";
      document.getElementById('txtPinCode').value = doctor.zip;
      document.getElementById('txtPhone').value = doctor.phone;
      document.getElementById('txtMobile').value = doctor.mobileNumber;
      cityAgainstState(doctor.city);
      subCityAgainstCity(doctor.city);
      setSubCitySelect(doctor.subCity)
    }
  };
  useEffect(() => {
    listing();
    document.getElementById('cmbCountry').value = "India";
  }, []);
  const listing = () => {
    setHospitalData(props.getHospitalDropdown.hospitalData);
    setStateData(props.getDropdownLists.stateData);
    setCityArray(props.getDropdownLists.cityData);
    setSubCityArray(props.getDropdownLists.subCityData);
    setCountryArrayCopy(props.getDropdownLists.countryData);
    setStateDataCopy(props.getDropdownLists.stateData)
  };
  const cityAgainstState = (cityName) => {
    const state = document.getElementById('cmbState').value;
    const stateObj = stateData.find((o) => o.stateName === state);
    const cities = cityArray.filter((o) => o.stateName === state);
    setCityArrayCopy(cities);
    const countries = countryArray.filter((o) => o.countryId === stateObj?.countryId);
    setCountryArray(countries);
    if (countries.length === 1) {
      document.getElementById('cmbCountry').value = countries[0].countryName;
    }
    if (cityName && cities.length) {
      setCityValue(cityName)
    }
  };
  useEffect(() => {
    document.getElementById('cmbSubCity').value = subCitySelect ? subCitySelect : 'Select';
  }, [subCityArrayCopy]);
  const subCityAgainstCity = (subCityEdit) => {
    const city = document.getElementById('cmbCity').value;
    setCityValue(subCityEdit)
    const subCities = subCityArray.filter((o) => o.cityName === (subCityEdit || city));
    setSubCityArrayCopy(subCities);
    document.getElementById('cmbSubCity').value = subCityEdit ? subCityEdit : 'Select';
  };
  const addOfficeHoursDetails = () => {
    const hospitalNameElem = document.getElementById("cmbHospitalName");
    const hospitalIdElem = document.getElementById("txtHospitalIdNew");
    if (!hospitalNameElem.value) {
        alert("Hospital Name is required");
        // hospitalNameElem.focus();
        return;
    }
    // const filteredData = hospitalData.find((hospital) => 
    //     hospital.hospitalName.toLowerCase() === hospitalNameElem.value.toLowerCase()
    // );
    const filteredDataId = hospitalData.find((hospital) => 
        hospital.hospitalId.toString() === hospitalIdElem.value.trim()
    );
    if ( !filteredDataId ) {
        alert("Invalid Hospital Name or Hospital ID");
        return;
    }
    const shedule1 = {
      sundayTo: document.getElementById("sh1SunTo").value,
      sundayFrom: document.getElementById("sh1SunFrom").value,
      mondayTo: document.getElementById("sh1MonTo").value,
      mondayFrom: document.getElementById("sh1MonFrom").value,
      tuesdayTo: document.getElementById("sh1TueTo").value,
      tuesdayFrom: document.getElementById("sh1TueFrom").value,
      wednesdayTo: document.getElementById("sh1WedTo").value,
      wednesdayFrom: document.getElementById("sh1WedFrom").value,
      thursdayTo: document.getElementById("sh1ThurTo").value,
      thursdayFrom: document.getElementById("sh1ThurFrom").value,
      fridayTo: document.getElementById("sh1FriTo").value,
      fridayFrom: document.getElementById("sh1FriFrom").value,
      saturdayTo: document.getElementById("sh1SatTo").value,
      saturdayFrom: document.getElementById("sh1SatFrom").value,
    };
    const shedule2 = {
      sundayTo: document.getElementById("sh2SunTo").value,
      sundayFrom: document.getElementById("sh2SunFrom").value,
      mondayTo: document.getElementById("sh2MonTo").value,
      mondayFrom: document.getElementById("sh2MonFrom").value,
      tuesdayTo: document.getElementById("sh2TueTo").value,
      tuesdayFrom: document.getElementById("sh2TueFrom").value,
      wednesdayTo: document.getElementById("sh2WedTo").value,
      wednesdayFrom: document.getElementById("sh2WedFrom").value,
      thursdayTo: document.getElementById("sh2ThurTo").value,
      thursdayFrom: document.getElementById("sh2ThurFrom").value,
      fridayTo: document.getElementById("sh2FriTo").value,
      fridayFrom: document.getElementById("sh2FriFrom").value,
      saturdayTo: document.getElementById("sh2SatTo").value,
      saturdayFrom: document.getElementById("sh2SatFrom").value,
    };

    const newOfficeHours = {
      hospitalId: document.getElementById("txtHospitalIdNew").value,
      hasOwner: "false",//as per the request from team purple health 
      schedule: [shedule1, shedule2],
    };

    const index = officeHoursDetails.findIndex((x) => x.hospitalId.toString() === newOfficeHours.hospitalId);

    if (index < 0) {
      setOfficeHoursDetails([...officeHoursDetails, newOfficeHours]);
    }

    // Clear input fields
    document.getElementById("cmbHospitalName").value = "";
    document.getElementById("txtHospitalIdNew").value = "";
    document.getElementById("sh1SunTo").value = "0";
    document.getElementById("sh1SunFrom").value = "0";
    document.getElementById("sh1MonTo").value = "0";
    document.getElementById("sh1MonFrom").value = "0";
    document.getElementById("sh1TueTo").value = "0";
    document.getElementById("sh1TueFrom").value = "0";
    document.getElementById("sh1WedTo").value = "0";
    document.getElementById("sh1WedFrom").value = "0";
    document.getElementById("sh1ThurTo").value = "0";
    document.getElementById("sh1ThurFrom").value = "0";
    document.getElementById("sh1FriTo").value = "0";
    document.getElementById("sh1FriFrom").value = "0";
    document.getElementById("sh1SatTo").value = "0";
    document.getElementById("sh1SatFrom").value = "0";
    document.getElementById("sh2SunTo").value = "0";
    document.getElementById("sh2SunFrom").value = "0";
    document.getElementById("sh2MonTo").value = "0";
    document.getElementById("sh2MonFrom").value = "0";
    document.getElementById("sh2TueTo").value = "0";
    document.getElementById("sh2TueFrom").value = "0";
    document.getElementById("sh2WedTo").value = "0";
    document.getElementById("sh2WedFrom").value = "0";
    document.getElementById("sh2ThurTo").value = "0";
    document.getElementById("sh2ThurFrom").value = "0";
    document.getElementById("sh2FriTo").value = "0";
    document.getElementById("sh2FriFrom").value = "0";
    document.getElementById("sh2SatTo").value = "0";
    document.getElementById("sh2SatFrom").value = "0";

  };
  const removeOfficeHoureDetails = (hospitalId) => {
    const updatedData = officeHoursDetails.filter((e) => e.hospitalId !== hospitalId);
    setOfficeHoursDetails(updatedData);
  };
  const handleChange = (event) => {
    const { id, value } = event.target;
    const re = /^[0-9\b]{0,6}$/;
    if (id === "txtPinCode" && (value === "" || re.test(value))) {
      document.getElementById(id).value = value.slice(0, 6);
    } else if (/^[0-9\b]+$/.test(value)) {
      document.getElementById(id).value = value;
    }
  };
  const handlePhoneLimit = (event) => {
    const { id, value } = event.target;
    const re = /^[0-9]{0,10}$/;
    if (value === "" || re.test(value)) {
      document.getElementById(id).value = value.slice(0, 10);
    } else {
      alert("Only 10 Digit Numbers Allowed.");
    }
  };
  const setOfficeHoursData = (name, i, mode, objectVal, schedule) => {
    const newOfficeHoursDetails = [...officeHoursDetails];
    if (mode === "cmbHospitalName") {
      newOfficeHoursDetails[i].hospitalId = document.getElementById(name).value;
    } else if (mode === "isOwner") {
      newOfficeHoursDetails[i].hasOwner = document.getElementById(name).value;
    } else {
      newOfficeHoursDetails[i].schedule[schedule][objectVal] = document.getElementById(name).value;
    }
    setOfficeHoursDetails(newOfficeHoursDetails);
  };
  const hospitalNameOk = (e) => {
    setHospitalArray([]);
    const { id, value } = e.target;
    if (id === "cmbHospitalName" && value.length > 3) {
      document.getElementById("cmbHospitalName").focus()
      document.getElementById("txtHospitalIdNew").value = "";
      const filteredData = hospitalData.filter((hospital) =>
        hospital.hospitalName.toLowerCase().includes(value.toLowerCase())
      );
      setHospitalArray(filteredData);
    } else if (id === "txtHospitalIdNew") {
      // document.getElementById("cmbHospitalName").focus()
      document.getElementById("cmbHospitalName").value = "";
      const filteredData = hospitalData.filter((hospital) =>
        hospital.hospitalId.toString().includes(value.toString())
      );
      setHospitalArray(filteredData);
    }
  };
  const hospitalDetails = (key) => {
    const hospitalId = key ? document.getElementById("cmbHospitalName" + key).value : document.getElementById("txtHospitalIdNew").value;
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalViewDetails",
        hospitalId: hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        const result = data.resultData[0];
        document.getElementById('txtAddress').value = result.address;
        document.getElementById('cmbState').value = result.stateName;
        document.getElementById('cmbCountry').value = result.countryName;
        document.getElementById('txtPinCode').value = result.zip;
        document.getElementById('txtPhone').value = result.phone;
        cityAgainstState(result.city);
        subCityAgainstCity(result.city);
      }
    });
  };
  useImperativeHandle(ref, () => ({
    OfficeHours: () => ({
      DoctorOfficeHours: officeHoursDetails
    })
  }));
  const hospitalIdSet = (hospitalId, hospitalName) => {
    setHospitalArray([]);
    document.getElementById("cmbHospitalName").value = hospitalName;
    document.getElementById("txtHospitalIdNew").value = hospitalId;
    hospitalDetails("");
  };
  return (
    <div className="bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          <div className="hspworkdetails-wrapper pb-5 pt-5 mb-5">
            <div className="doctorhspwrkdetal-ofcehour mb-1 mt-1" id="doc_titme">
              <div className="addimgeDivHsp">
                <img
                  src={AddImg}
                  alt="Add"
                  onClick={() => addOfficeHoursDetails()}
                />
              </div>
              <div className="hspinfodoctor-contentdiv doctorofcrhr-contentdiv pb-3 pt-1">
                <label className="hspofce-labelmob">
                  Hospital Name <span className="hspifoMantory">*</span>
                </label>
                <input
                  name="cmbHospitalName"
                  type="text"
                  id="cmbHospitalName"
                  placeholder="Hospital Name"
                  className="hspInfofieldinput hspinput-mobdoctr"
                  onChange={hospitalNameOk}
                />
                <input
                  type="text"
                  id="txtHospitalIdNew"
                  name="txtHospitalIdNew"
                  className="hspInfofieldinput dctrofce-inputhspid"
                  placeholder="Hospital Id"
                  onChange={hospitalNameOk}
                />
                {hospitalArray.length > 0 &&
                  <ul className="hospitallistUl hospitallistUlclinic-width">
                    {hospitalArray.map((hospitalObj, index) => (
                      <li
                        key={index}
                        className="hosptalList"// Corrected typo in class name
                        onClick={() =>
                          hospitalIdSet(hospitalObj.hospitalId, hospitalObj.hospitalName)
                        }
                      >
                        {hospitalObj.hospitalName}
                      </li>
                    ))}
                  </ul>

                  // <ul className="hospitallistUl hospitallistUlclinic-width">
                  //   <li className="hosptalList">No data found</li>
                  // </ul>
                }
              </div>
              <h4 className="officehrsHead" id="off_hr">
                Office Hours<span className="hspifoMantory">*</span>
              </h4>
              <div>
                <div className="pb-3">
                  <label className="sheduleLabeldoctoffce"></label>
                  <div className="sheduledivdoctr">schedule 1</div>
                  <div className="sheduledivdoctr">schedule 2</div>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Monday</label>
                  <OfficeTime
                    idValue="sh1MonFrom"
                    nameValue="sh1"
                    timeType="From"
                    id="sh1MonFrom"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh1MonTo"
                    nameValue="sh1"
                    timeType="To"
                    id="sh1MonTo"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2MonFrom"
                    nameValue="sh2"
                    timeType="From"
                    id="sh2MonFrom"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2MonTo"
                    id="sh2MonTo"
                    nameValue="sh2"
                    timeType="To"
                  ></OfficeTime>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Tuesday</label>
                  <OfficeTime
                    idValue="sh1TueFrom"
                    nameValue="sh1"
                    timeType="From"
                    id="sh1TueFrom"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh1TueTo"
                    id="sh1TueTo"
                    nameValue="sh1"
                    timeType="To"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2TueFrom"
                    id="sh2TueFrom"
                    nameValue="sh2"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2TueTo"
                    nameValue="sh2"
                    id="sh2TueTo"
                    timeType="To"
                  ></OfficeTime>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Wednesday</label>
                  <OfficeTime
                    idValue="sh1WedFrom"
                    nameValue="sh1"
                    timeType="From"
                    id="sh1WedFrom"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh1WedTo"
                    id="sh1WedTo"
                    nameValue="sh1"
                    timeType="To"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2WedFrom"
                    nameValue="sh2"
                    id="sh2WedFrom"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2WedTo"
                    nameValue="sh2"
                    id="sh2WedTo"
                    timeType="To"
                  ></OfficeTime>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Thursday</label>
                  <OfficeTime
                    idValue="sh1ThurFrom"
                    nameValue="sh1"
                    id="sh1ThurFrom"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh1ThurTo"
                    nameValue="sh1"
                    id="sh1ThurTo"
                    timeType="To"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2ThurFrom"
                    nameValue="sh2"
                    timeType="From"
                    id="sh2ThurFrom"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2ThurTo"
                    nameValue="sh2"
                    id="sh2ThurTo"
                    timeType="To"
                  ></OfficeTime>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Friday</label>
                  <OfficeTime
                    idValue="sh1FriFrom"
                    id="sh1FriFrom"
                    nameValue="sh1"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh1FriTo"
                    nameValue="sh1"
                    id="sh1FriTo"
                    timeType="To"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2FriFrom"
                    nameValue="sh2"
                    timeType="From"
                    id="sh2FriFrom"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2FriTo"
                    nameValue="sh2"
                    id="sh2FriTo"
                    timeType="To"
                  ></OfficeTime>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Saturday</label>
                  <OfficeTime
                    idValue="sh1SatFrom"
                    nameValue="sh1"
                    id="sh1SatFrom"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh1SatTo"
                    id="sh1SatTo"
                    nameValue="sh1"
                    timeType="To"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2SatFrom"
                    id="sh2SatFrom"
                    nameValue="sh2"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    idValue="sh2SatTo"
                    id="sh2SatTo"
                    nameValue="sh2"
                    timeType="To"
                  ></OfficeTime>
                </div>
                <div className="offcehrDaydiv doctroffcehrDaydiv">
                  <label>Sunday</label>
                  <OfficeTime
                    idValue="sh1SunFrom"
                    id="sh1SunFrom"
                    nameValue="sh1"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    id="sh1SunTo"
                    idValue="sh1SunTo"
                    nameValue="sh1"
                    timeType="To"
                  ></OfficeTime>
                  <OfficeTime
                    id="sh2SunFrom"
                    idValue="sh2SunFrom"
                    nameValue="sh2"
                    timeType="From"
                  ></OfficeTime>
                  <OfficeTime
                    id="sh2SunTo"
                    idValue="sh2SunTo"
                    nameValue="sh2"
                    timeType="To"
                  ></OfficeTime>
                </div>
              </div>
            </div>
            {officeHoursDetails?.map((Obj, i) => {
              return (
                <div
                  key={Obj.hospitalId}
                  className="doctorhspwrkdetal-ofcehour mb-1 mt-1"
                >
                  <div className="addimgeDivHsp">
                    <img
                      src={Closebtn}
                      alt="Close"
                      onClick={() =>
                        removeOfficeHoureDetails(Obj.hospitalId)
                      }
                      width="20"
                    />
                  </div>
                  <div className="hspinfodoctor-contentdiv doctorofcrhr-contentdiv pb-3 pt-1">
                    <label>
                      Hospital Name <span className="hspifoMantory">*</span>
                    </label>
                    <select
                      key={i}
                      name={"cmbHospitalName" + i}
                      id={"cmbHospitalName" + i}
                      className="hspInfofieldinput"
                      value={Obj.hospitalId}
                      onChange={(e) =>
                        setOfficeHoursData(
                          "cmbHospitalName" + i,
                          i,
                          "cmbHospitalName",
                          "",
                          ""
                        )
                      }
                    >
                      <option value="0">Select Hospital</option>
                      {hospitalData.map((hospitalObj, i) => {
                        return (
                          <option
                            key={hospitalObj.hospitalId}
                            value={hospitalObj.hospitalId}
                          >
                            {hospitalObj.hospitalName}-
                            {hospitalObj.hospitalId}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <h4 className="officehrsHead">
                    Office Hours<span className="hspifoMantory">*</span>
                  </h4>
                  <div>
                    <div className="doctroffcehrDay hspinfodoctor-contentdiv mb-4">
                      <label>Hospital Owner</label>
                      <select
                        key={i}
                        name={"isOwner" + i}
                        id={"isOwner" + i}
                        value={Obj.hasOwner}
                        className="hspInfofieldinput "
                        onChange={(e) => {
                          setOfficeHoursData(
                            "isOwner" + i,
                            i,
                            "isOwner",
                            "",
                            ""
                          );
                          hospitalDetails(i);
                        }}
                      >
                        <option  value="true">Yes</option>
                        <option  value="false">No</option>
                      </select>
                    </div>
                    <div className="pb-3">
                      <label className="sheduleLabeldoctoffce"></label>
                      <div className="sheduledivdoctr">schedule 1</div>
                      <div className="sheduledivdoctr">schedule 2</div>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Monday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].mondayFrom}
                        objectVal={"mondayFrom"}
                        schedule={0}
                        idValue={"sh1MonFrom" + i}
                        nameValue={"sh1MonFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].mondayTo}
                        objectVal={"mondayTo"}
                        schedule={0}
                        idValue={"sh1MonTo" + i}
                        nameValue={"sh1MonTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].mondayFrom}
                        objectVal={"mondayFrom"}
                        schedule={1}
                        idValue={"sh2MonFrom" + i}
                        nameValue={"sh2MonFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].mondayTo}
                        objectVal={"mondayTo"}
                        schedule={1}
                        idValue={"sh2MonTo" + i}
                        nameValue={"sh2MonTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Tuesday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].tuesdayFrom}
                        objectVal={"tuesdayFrom"}
                        schedule={0}
                        idValue={"sh1TueFrom" + i}
                        nameValue={"sh1TueFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].tuesdayTo}
                        objectVal={"tuesdayTo"}
                        schedule={0}
                        idValue={"sh1TueTo" + i}
                        nameValue={"sh1TueTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].tuesdayFrom}
                        objectVal={"tuesdayFrom"}
                        schedule={1}
                        idValue={"sh2TueFrom" + i}
                        nameValue={"sh2TueFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].tuesdayTo}
                        objectVal={"tuesdayTo"}
                        schedule={1}
                        idValue={"sh2TueTo" + i}
                        nameValue={"sh2TueTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Wednesday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].wednesdayFrom}
                        objectVal={"wednesdayFrom"}
                        schedule={0}
                        idValue={"sh1WedFrom" + i}
                        nameValue={"sh1WedFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].wednesdayTo}
                        objectVal={"wednesdayTo"}
                        schedule={0}
                        idValue={"sh1WedTo" + i}
                        nameValue={"sh1WedTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].wednesdayFrom}
                        objectVal={"wednesdayFrom"}
                        schedule={1}
                        idValue={"sh2WedFrom" + i}
                        nameValue={"sh2WedFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].wednesdayTo}
                        objectVal={"wednesdayTo"}
                        schedule={1}
                        idValue={"sh2WedTo" + i}
                        nameValue={"sh2WedTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Thursday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].thursdayFrom}
                        objectVal={"thursdayFrom"}
                        schedule={0}
                        idValue={"sh1ThurFrom" + i}
                        nameValue={"sh1ThurFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].thursdayTo}
                        objectVal={"thursdayTo"}
                        schedule={0}
                        idValue={"sh1ThurTo" + i}
                        nameValue={"sh1ThurTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].thursdayFrom}
                        objectVal={"thursdayFrom"}
                        schedule={1}
                        idValue={"sh2ThurFrom" + i}
                        nameValue={"sh2ThurFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].thursdayTo}
                        objectVal={"thursdayTo"}
                        schedule={1}
                        idValue={"sh2ThurTo" + i}
                        nameValue={"sh2ThurTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Friday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].fridayFrom}
                        objectVal={"fridayFrom"}
                        schedule={0}
                        idValue={"sh1FriFrom" + i}
                        nameValue={"sh1FriFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].fridayTo}
                        objectVal={"fridayTo"}
                        schedule={0}
                        idValue={"sh1FriTo" + i}
                        nameValue={"sh1FriTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].fridayFrom}
                        objectVal={"fridayFrom"}
                        schedule={1}
                        idValue={"sh2FriFrom" + i}
                        nameValue={"sh2FriFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].fridayTo}
                        objectVal={"fridayTo"}
                        schedule={1}
                        idValue={"sh2FriTo" + i}
                        nameValue={"sh2FriTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Saturday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].saturdayFrom}
                        objectVal={"saturdayFrom"}
                        schedule={0}
                        idValue={"sh1SatFrom" + i}
                        nameValue={"sh1SatFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].saturdayTo}
                        objectVal={"saturdayTo"}
                        schedule={0}
                        idValue={"sh1SatTo" + i}
                        nameValue={"sh1SatTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].saturdayFrom}
                        objectVal={"saturdayFrom"}
                        schedule={1}
                        idValue={"sh2SatFrom" + i}
                        nameValue={"sh2SatFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].saturdayTo}
                        objectVal={"saturdayTo"}
                        schedule={1}
                        idValue={"sh2SatTo" + i}
                        nameValue={"sh2SatTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                    <div className="offcehrDaydiv doctroffcehrDaydiv">
                      <label>Sunday</label>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].sundayFrom}
                        objectVal={"sundayFrom"}
                        schedule={0}
                        idValue={"sh1SunFrom" + i}
                        nameValue={"sh1SunFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[0].sundayTo}
                        objectVal={"sundayTo"}
                        schedule={0}
                        idValue={"sh1SunTo" + i}
                        nameValue={"sh1SunTo" + i}
                        timeType="To"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].sundayFrom}
                        objectVal={"sundayFrom"}
                        schedule={1}
                        idValue={"sh2SunFrom" + i}
                        nameValue={"sh2SunFrom" + i}
                        timeType="From"
                      ></OfficeTime>
                      <OfficeTime
                        setOfficeHoursData={setOfficeHoursData}
                        index={i}
                        inputVal={Obj.schedule[1].sundayTo}
                        objectVal={"sundayTo"}
                        schedule={1}
                        idValue={"sh2SunTo" + i}
                        nameValue={"sh2SunTo" + i}
                        timeType="To"
                      ></OfficeTime>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="container">
            <div className="hspinfo-contentdiv">
              <label>
                Email <span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                id="txtEmail"
                className="hspInfofieldinput Infoinputwidthdoctr"
                placeholder="Email"
              />
            </div>
            <div className="hspinfo-contentdiv">
              <label>
             Hospital Address <span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                id="txtAddress"
                name="txtAddress"
                className="hspInfofieldinput Infoinputwidthdoctr"
                placeholder="Address"
              />
            </div>
            <div className="hspinfo-contentdiv">
              <label>
                Country <span className="hspifoMantory">*</span>
              </label>
              <select
                name="cmbCountry"
                id="cmbCountry"
                className="hspInfofieldinput Infoinputwidthdoctr"
              >
                <option value="0">Select</option>
                {countryArrayCopy.map((LangObj, i) => {
                  return (
                    <option
                      key={i}
                      value={LangObj.countryName}
                    >
                      {LangObj.countryName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="hspinfo-contentdiv">
              <label>
                State <span className="hspifoMantory">*</span>
              </label>
              <select
                name="cmbState"
                id="cmbState"
                className="hspInfofieldinput Infoinputwidthdoctr"
                onChange={() => cityAgainstState()}
              >
                <option value="0">Select </option>
                {stateDataCopy.map((stateObj, i) => {
                  return (
                    <option
                      key={i}
                      value={stateObj.stateName}
                    >
                      {stateObj.stateName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="hspinfo-contentdiv">
              <label>
                City <span className="hspifoMantory">*</span>
              </label>
              <select
                name="cmbCity"
                value={cityValue}
                id="cmbCity"
                className="hspInfofieldinput Infoinputwidthdoctr"
                onChange={() => subCityAgainstCity()}
              >
                <option value="0">Select </option>
                {cityArrayCopy.map((stateObj, i) => {
                  return (
                    <option key={i} value={stateObj.cityName}>
                      {stateObj.cityName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="hspinfo-contentdiv">
              <label>Sub City </label>
              <select
                name="cmbSubCity"
                id="cmbSubCity"
                value={subCitySelect}
                onChange={(e) => { setSubCitySelect(e.target.value) }}
                className="hspInfofieldinput Infoinputwidthdoctr"
              >
                <option value="0">Select</option>
                {subCityArrayCopy.map((stateObj, i) => {
                  return (
                    <option
                      key={i}
                      value={stateObj.subcityName}
                    >
                      {stateObj.subcityName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="hspinfo-contentdiv">
              <label>
                Pin Code <span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                id="txtPinCode"
                name="txtPinCode"
                className="hspInfofieldinput Infoinputwidthdoctr"
                placeholder="Pin Code"
                onKeyUp={(e) => handleChange(e)}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="hspinfo-contentdiv">
              <label>Phone </label>
              <input
                type="text"
                id="txtPhone"
                name="txtPhone"
                className="hspInfofieldinput Infoinputwidthdoctr"
                placeholder="Phone"
                onKeyUp={(e) => handleChange(e)}
                onChange={handlePhoneLimit}
              />
            </div>
            <div className="hspinfo-contentdiv">
              <label>
                Mobile <span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                id="txtMobile"
                name="txtMobile"
                className="hspInfofieldinput Infoinputwidthdoctr"
                placeholder="Mobile"
                onChange={handlePhoneLimit}
              />
            </div>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}></Suspense>
    </div>
  );
})
export default DoctorOfficeHours;

