import React, { Component, Suspense } from "react";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { formatDatePickerToddMMyyyy } from "../../apiService";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { apiCalling } from "../../apiService";
import Plus from "../../image/icons/plus.png";
import Close from "../../image/icons/sh-cancel.png";

import AWS from "aws-sdk";
// import TagsInput from "react-tagsinput";
// import "react-tagsinput/react-tagsinput.css";

var s3;
var imageFile;

const navigation = [
  { pageName: "Dashboard", url: "/admin/dashboardAdmin" },
  {
    pageName: "Health info Dashboard",
    url: "/adminManagement/healthInformation",
  },
  {
    pageName: "Health Info",
    url: "/adminManagement/healthInfo",
    current: true,
  },
];

class helthinfoAddEdit extends Component {
  state = {
    healthInfoId: "",
    healthInfoCategoryId: 0,
    healthInfoSubCategoryId: 0,
    description: "",
    featured: false,
    isVideo: "",
    postedDate: "",
    profileImage: "",
    profileImageNameOnly: "",
    readCount: "",
    shortDescription: "",
    slug: "",
    sortOrder: 0,
    status: true,
    title: "",
    likeCount: "",
    isVerified: false,
    url: "",
    isSponsor: false,
    createdBy: "",
    pmSiteId: "",
    hospitalName: "",
    modifiedBy: "",
    modifiedOn: "",
    cityName: "",
    state: "",
    tags: [],
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    ),
    categoryList: [],
    categorySubData: [],
    stateList: [],
    cityList: [],
    organizationList: [],
    organizationIds: [],
    stateIds: [],
    tempState: { value: "0" },
    tempCity: { value: "0" },
    tempOrganization: { value: "0" },
    createdByHealthInfo: [],
    currentTab: "healthInfoTab",
    s3bucketAccessKey: this.props.imagePath.s3bucketAccessKey,
    AWSAccessKeyId: this.props.imagePath.s3bucketSecretKey,
    bucket: this.props.imagePath.PurpleHealthS3bucket,
    bucketFolder: this.props.imagePath.S3BucketMainFolder,
    S3BucketBlogImages: this.props.imagePath.S3BucketBlogImages,
  };

  componentDidMount() {
    this.getCategoryList();
    this.getCategorySubData();
    this.getCreatedByHealthInfo();
    this.getStateList();
    this.getOrgList();
    //debugger;
    if (this.props.healthInfoId > 0) {
      this.getHealthInfo();
    }
  }

  // componentDidUpdate(){
  //   $(".loader").hide();
  // }

  getStateList() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStateCity",
        stateId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          stateList: data.state,
        });
      }
    });
  }

  getStateCityList(stateId) {
    
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getStateCity",
        stateId: stateId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          cityList: data.city,
        });
      }
      $(".loader").hide();
    });
  }

  getOrgList() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAdApprovalCompanyList",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          organizationList: data.adApprovalCompanyListData,
        });
      }
    });
  }

  getCategoryList() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthInfoCategoryList",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          categoryList: data.category,
        });
      }
    });
  }

  getCategorySubData() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthInfoSubCategoryList",
        healthInfoCategoryId: this.state.healthInfoCategoryId,
      }),
    };
    this.setState({ healthInfoSubCategoryId: 0 });
    //if (this.state.healthInfoCategoryId > 0) {
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          categorySubData: data.categorySubData,
        });
      }
    });
    //}
  }

  getCreatedByHealthInfo() {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "createdByHealthInfo",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState({
          createdByHealthInfo: data.resultData,
        });
      }
    });
  }

  removeStateCity = (stateId, cityId) => {
    let stateIds = this.state.stateIds.filter((sc) => {
      return !(sc.stateIds == stateId && sc.cityIds == cityId);
    });
    this.setState({ stateIds });
  };

  addStateCity = () => {
    if (this.state.tempState.value != "0" && this.state.tempCity.value != "0") {
      let tempStateTarget = this.state.tempState;
      let tempCityTarget = this.state.tempCity;
      let stateIds = this.state.stateIds.filter((sc) => {
        return !(
          sc.stateIds == tempStateTarget.value &&
          sc.cityIds == tempCityTarget.value
        );
      });

      stateIds.push({
        stateIds: tempStateTarget.value,
        stateName: tempStateTarget.options[tempStateTarget.selectedIndex].text,
        cityIds: tempCityTarget.value,
        cityName: tempCityTarget.options[tempCityTarget.selectedIndex].text,
      });

      this.setState({
        stateIds: stateIds,
        tempState: { value: "0" },
        tempCity: { value: "0" },
      });
    } else {
      alert("Please add state and city before proceeding..!");
    }
  };

  addOrganization = () => {
    if (this.state.tempOrganization.value != "0") {
      let tempOrganizationTarget = this.state.tempOrganization;
      let organizationIds = this.state.organizationIds.filter((sc) => {
        return !(sc.pmSiteId == tempOrganizationTarget.value);
      });

      organizationIds.push({
        pmSiteId: tempOrganizationTarget.value,
        siteName:
          tempOrganizationTarget.options[tempOrganizationTarget.selectedIndex]
            .text,
      });

      this.setState({
        organizationIds: organizationIds,
        tempOrganization: { value: "0" },
      });
    } else {
      alert("Please add organization before proceeding..!");
    }
  };

  removeOrganization = (pmSiteId) => {
    let organizationIds = this.state.stateIds.filter((sc) => {
      return !(sc.pmSiteId == pmSiteId);
    });
    this.setState({ organizationIds });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  dateChangeHandler = (date, id) => {
    this.setState({ [id]: formatDatePickerToddMMyyyy(date) });
  };

  changeHandler = (e) => {
    if (e.target.type == "checkbox") {
      this.setState({ [e.target.id]: !e.target.checked });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  handleStateCityOrgchangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target });
  };

  handleTagChange = (e) => {
    let tags = this.state.tags.filter(
      (tag) => tag.tagName.toLowerCase() != e.target.value.toLowerCase()
    );
    if (e.target.value.split(" ").join("") != "") {
      tags.push({ tagName: e.target.value });
      this.setState({ tags });
    }
    e.target.value = "";
  };

  handleRemoveTag = (tag) => {
    let tags = this.state.tags.filter(
      (t) => t.tagName.toLowerCase() != tag.toLowerCase()
    );
    this.setState({ tags });
  };

  disableDateField(id) {
    $("#" + id).attr("readonly", "readonly");
    this.setState({ [id]: null });
  }

  getHealthInfo = () => {
    //debugger;
    let bucket =
      this.props.imagePath.s3BucketRelianceAddressPath +
      "/" +
      this.props.imagePath.S3BucketMainFolder +
      "/" +
      this.props.imagePath.S3BucketBlogImages +
      "/";

    const editHealthInfo = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editHealthInfo",
        healthInfoId: this.props.healthInfoId,
      }),
    };
    $(".loader").show();
    //debugger;
    apiCalling(editHealthInfo).then((data) => {
      // console.log(data,'zzzzzzzzzzzzzz')
      debugger;
      if (data.success === "1") {
        //debugger;
        this.setState(
          {
            healthInfoId: data.healthInfoData[0].healthInfoId,
            title: data.healthInfoData[0].title,
            isSponsor: data.healthInfoData[0].isSponsor,
            profileImage: bucket + data.healthInfoData[0].profileImage,
            shortDescription: data.healthInfoData[0].shortDescription,
            createdBy: data.healthInfoData[0].createdBy,
            likeCount: data.healthInfoData[0].likeCount,
            readCount: data.healthInfoData[0].readCount,
            postedDate: data.healthInfoData[0].postedDate,
            status: data.healthInfoData[0].articleStatus,
            hospitalName: data.healthInfoData[0].hospitalName,
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data.healthInfoData[0].description)
              )
            ),
            sortOrder: data.healthInfoData[0].sortOrder,
            modifiedBy: data.healthInfoData[0].modifiedBy,
            modifiedOn: data.healthInfoData[0].modifiedOn,
            featured:
              data.healthInfoData[0].featured == ""
                ? false
                : data.healthInfoData[0].featured,
            isVideo: data.healthInfoData[0].isVideo,
            slug: data.healthInfoData[0].slug,

            isVerified:
              data.healthInfoData[0].isVerify == "" ||
              data.healthInfoData[0].isVerify == undefined ||
              data.healthInfoData[0].isVerify == null
                ? false
                : data.healthInfoData[0].isVerify,

            cityName: data.healthInfoData[0].cityName,
            state: data.healthInfoData[0].state,
            healthInfoCategoryId: data.healthInfoData[0].healthInfoCategoryId,
            healthInfoSubCategoryId:
              data.healthInfoData[0].healthInfoSubCategoryId,
            tags: data.tags,
            organizationIds: data.pmSiteData == "" ? [] : data.pmSiteData,
            stateIds: data.cityStateData,
          },
          () => {
            $(".loader").hide();
          }
        );

        $("#healthInfoCategoryId").change();

        $("#healthInfoSubCategoryId").prop(
          "disabled",
          this.state.healthInfoCategoryId == "0" ? true : false
        );
      }
    });
  };

  validateForm() {
    let editorState = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    if (this.state.title == "") {
      alert("Title required.");
    } else if (this.state.isVerified === "") {
      alert("Is Verify required.");
      return false;
    } else if (this.state.healthInfoCategoryId === "") {
      alert("Category required.");
      return false;
    // } else if (this.state.hospitalName === "") {
    //   alert("Hospital Name required.");
    //   return false;
      // } else if (this.state.healthInfoSubCategoryId === "") {
      //   alert("sub-category required.");
      //   return false;
    } else if (this.state.sortOrder == "") {
      alert("sort order required.");
      return false;
    } else if (this.state.postedDate == "") {
      alert("Posted Date required.");
      return false;
    } else if (this.state.featured === "") {
      alert("Featured required.");
      return false;
    } else if (this.state.readCount == "") {
      alert("Read Count required.");
      return false;
    } else if (this.state.likeCount == "") {
      alert("Like Count required.");
      return false;
    } else if (this.state.slug == "") {
      alert("Slug required.");
      return false;
    } else if (this.state.tags == []) {
      alert("Tags required.");
      return false;
    } else if (editorState == "") {
      alert("Description required.");
      return false;
    } else {
      return true;
    }
  }

  cancelHandle() {
    this.props.changeMode("list", navigation, "Add/Edit Health Information");
  }

  handlesaveHealthInfoDetails = () => {
    if (this.validateForm()) {
      let pmSiteId = [];
      this.state.organizationIds.map((org) => {
        pmSiteId.push(org.pmSiteId);
      });

      const saveHealthInfoDetails = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveHealthInfoDetails",
          healthInfoId: this.state.healthInfoId,
          healthInfoCategoryId: this.state.healthInfoCategoryId,
          healthInfoSubCategoryId: this.state.healthInfoSubCategoryId,
          description: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          featured: this.state.featured,
          isVideo: this.state.isVideo,
          postedDate: this.state.postedDate,
          profileImage: this.state.profileImageNameOnly,
          readCount: this.state.readCount,
          shortDescription: this.state.shortDescription,
          slug: this.state.slug,
          sortOrder: this.state.sortOrder,
          status: this.state.status,
          title: this.state.title,
          likeCount: this.state.likeCount,
          isVerified: this.state.isVerified,
          url: this.state.url,
          isSponsor: this.state.isSponsor,
          createdBy: this.state.createdBy,
          tagName: this.state.tags,
          pmSiteId: pmSiteId.toString(),
          stateIds: this.state.stateIds,
        }),
      };
      $(".loader").show();
      //debugger;
      apiCalling(saveHealthInfoDetails).then((data) => {
        //debugger;
        $(".loader").hide();
        if (data.success === "1") {
          let message =
            this.props.healthInfoId > 0
              ? "Updated successfully "
              : "Save successfully";
          alert(message);
          this.cancelHandle();
        } else if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    }
  };

  changeTab = (e) => {
    this.setState({ currentTab: e.target.id });
  };

  fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 30145728) {
      alert("File size must under 30 MB!");
      return false;
    } else {
      var fileObj = e.target.files[0];
      this.setState({ fileNameWithPath: window.URL.createObjectURL(fileObj) });
      this.fileUpload(imageFile);
    }
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  async fileUpload(file) {
    $(".loader").show();
    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.AWSAccessKeyId,
      region: this.state.region,
    });

    var bucketParams = {
      Bucket:
        this.state.bucket +
        "/" +
        this.state.bucketFolder +
        "/" +
        this.state.S3BucketBlogImages,
    };

    s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let fileName = this.uuidv4() + file.name;
    await s3.upload(
      {
        Key: fileName,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your photo: ", err.message);
        } else if (data) {
          this.setState({
            profileImage: data.Location,
            profileImageNameOnly: fileName,
          });
          //return null;
        }
        $(".loader").hide();
      }
    );
  }

  tabData() {
    const { editorState } = this.state;
    if (this.state.currentTab === "healthInfoTab") {
      return (
        
        <div className="row col-md-12 m-0 doctr-wraper-border">
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Title<span className="hspifoMantory">*</span>{" "}
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="title"
                name="title"
                value={this.state.title}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Is Verified<span className="hspifoMantory">*</span>{" "}
            </label>
            {/* <div className="col-md-12"> */}
              <select
                className="hspInfofieldinput Infoinputwidthdoctr"
                id="isVerified"
                name="isVerified"
                value={this.state.isVerified}
                onInput={this.changeHandler}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Category<span className="hspifoMantory">*</span>{" "}
            </label>
            {/* <div className="col-md-12"> */}
              <select
                className="hspInfofieldinput Infoinputwidthdoctr"
                id="healthInfoCategoryId"
                name="healthInfoCategoryId"
                value={this.state.healthInfoCategoryId}
                onInput={this.changeHandler}
                onChange={() => {
                  $("#healthInfoSubCategoryId").prop(
                    "disabled",
                    this.state.healthInfoCategoryId == "0" ? true : false
                  );
                  this.getCategorySubData();
                }}
              >
                <option value="0">Select Category</option>
                {this.state.categoryList.map((category) => (
                  <option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>Sub-Category</label>
            {/* <div className="col-md-12"> */}
              <select
                className="hspInfofieldinput Infoinputwidthdoctr"
                id="healthInfoSubCategoryId"
                name="healthInfoSubCategoryId"
                value={this.state.healthInfoSubCategoryId}
                onInput={this.changeHandler}
                disabled
              >
                <option value="0">Select Sub-Category</option>
                {this.state.categorySubData.map((subcategory) => (
                  <option
                    key={subcategory.subCategoryId}
                    value={subcategory.subCategoryId}
                  >
                    {subcategory.subCategoryName}
                  </option>
                ))}
              </select>
            {/* </div> */}
          </div>
          {/* <div className="row-margin col-md-6">
            <label className="col-md-12 label-text">
              Hospital Name<span className="star-red">*</span>{" "}
            </label>
            <div className="col-md-12">
              <input
                type="text"
                className="input-design"
                id="hospitalName"
                name="hospitalName"
                value={this.state.hospitalName}
                onInput={this.changeHandler}
              />
            </div>
          </div> */}
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Created By<span className="hspifoMantory">*</span>{" "}
            </label>
            {/* <div className="col-md-12"> */}
              <select
                className="hspInfofieldinput Infoinputwidthdoctr"
                id="createdBy"
                name="createdBy"
                value={this.state.createdBy}
                onInput={this.changeHandler}
              >
                <option value="0">Select Created By</option>
                {this.state.createdByHealthInfo.map((createdBy) => (
                  <option key={createdBy.id} value={createdBy.id}>
                    {createdBy.emailId}
                  </option>
                ))}
              </select>
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>Modified By</label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="modifiedBy"
                name="modifiedBy"
                disabled
                value={this.state.modifiedBy}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>

          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>Modified On</label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="modifiedOn"
                name="modifiedOn"
                disabled
                value={this.state.modifiedOn}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Sort Order<span className="hspifoMantory">*</span>
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="sortOrder"
                name="sortOrder"
                value={this.state.sortOrder}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Status<span className="hspifoMantory">*</span>{" "}
            </label>
            {/* <div className="col-md-12"> */}
              <select
                className="hspInfofieldinput Infoinputwidthdoctr"
                id="state"
                name="state"
                value={this.state.state}
                onInput={this.changeHandler}
              >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
              </select>
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv react-date-dctr-width healthinfo-fielddiv">
            <label>
              Posted Date<span className="hspifoMantory">*</span>
            </label>
            {/* <div className="col-md-12"> */}
              <DatePicker
                id="postedDate"
                name="postedDate"
                placeholderText="Posted Date"
                className="doctrHsp-dateinput"
                // maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                dropdownMode="scroll"
                peekNextMonth
                disabledKeyboardNavigation
                onSelect={(date) => this.dateChangeHandler(date, "postedDate")}
                value={this.state.postedDate}
                dateFormat="dd/MM/yyyy"
                calendarClassName="react-date-picker"
                autoComplete="off"
                tabIndex="3"
                onKeyDown={() => this.disableDateField("postedDate")}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv checkbox-label-healthinfo">
            <label for="isVideo">
              Is Video
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="checkbox"
                name="isVideo"
                id="isVideo"
                className="mt-2"
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Featured<span className="hspifoMantory">*</span>{" "}
            </label>
            {/* <div className="col-md-12"> */}
              <select
                className="hspInfofieldinput Infoinputwidthdoctr"
                id="featured"
                name="featured"
                value={this.state.featured}
                onInput={this.changeHandler}
              >
                <option value="false">Not Featured</option>
                <option value="true">is Featured</option>
              </select>
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Read Count<span className="hspifoMantory">*</span>
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="readCount"
                name="readCount"
                value={this.state.readCount}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Slug<span className="hspifoMantory">*</span>
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="slug"
                name="slug"
                value={this.state.slug}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv">
            <label>
              Like Count<span className="hspifoMantory">*</span>
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="text"
                className="hspInfofieldinput"
                id="likeCount"
                name="likeCount"
                value={this.state.likeCount}
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="hspinfo-contentdiv healthinfo-fielddiv checkbox-label-healthinfo">
            <label for="isVideo">
              Is Sponsored
            </label>
            {/* <div className="col-md-12"> */}
              <input
                type="checkbox"
                name="isSponsor"
                id="isSponsor"
                className="mt-2"
                onInput={this.changeHandler}
              />
            {/* </div> */}
          </div>
          <div className="row-margin col-md-12 helathinfo-tag-mob">
            <label className="col-md-12 label-hspservice">
              Tags<span className="hspifoMantory">*</span>
            </label>
            <div className="col-md-12">
              <div className="input-design tag-fieldhealthinfo">
                {this.state.tags.map((t) => (
                  <span
                    style={{
                      border: "1px solid #d3d3d3",
                      background: "#e6e6e6",
                      fontWeight: "normal",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      marginRight: "5px",
                    }}
                  >
                    {t.tagName}
                    <a
                      style={{
                        cursor: "pointer",
                        marginLeft: "2px",
                      }}
                      onClick={() => {
                        this.handleRemoveTag(t.tagName);
                      }}
                    >
                      <img src={Close} className="img-fluid" width="15" />
                    </a>
                  </span>
                ))}
                <input
                  type="text"
                  style={{ width: "50%", border: "none" }}
                  placeholder="Add Tags"
                  onBlur={this.handleTagChange}
                ></input>
              </div>
              {/* <TagsInput 
                name="tags"
                value={this.state.tags}
                onChange={this.handleTagChange}                
                inputValue={this.state.tag}
                onChangeInput={this.handleChangeInput}
              /> */}
              {/* <input
                type="text"
                className="input-design"
                id="name"
                value={this.state.tags}
                onInput={this.changeHandler}
              /> */}
            </div>
          </div>
          <div className="row-margin col-md-12 helathinfo-tag-mob">
            <label className="col-md-12 label-hspservice">
              Short Description<span className="hspifoMantory">*</span>
            </label>
            <div className="col-md-12">
              <textarea
                value={this.state.shortDescription}
                onInput={this.changeHandler}
                id="shortDescription"
                style={{
                  minHeight: 80,
                  maxHeight: 90,
                }}
                className="textarea-hspservice"
              ></textarea>
            </div>
          </div>
          <div className="row-margin col-md-12 helathinfo-tag-mob">
            <label className="col-sm-12 label-hspservice">Description</label>
            <div className="col-sm-12">
              <Editor
                editorState={this.state.editorState}
                initialEditorState={editorState}
                toolbarclassName="toolbarclassName"
                wrapperclassName="redactor-wrapper redactor-wrapper-healthinfo"
                editorclassName="editorclassName"
                onEditorStateChange={this.onEditorStateChange}
                handlePastedText={() => false}
                editorStyle={{
                  minHeight: "200px",
                  maxHeight: "200px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  borderWidth: "1px",
                  borderColor: "#d3d3d3",
                  borderStyle: "solid",
                  // borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              />
            </div>
          </div>
          {this.renderstateCityOrg()}
        </div>
      );
    } else if (this.state.currentTab === "imageTab") {
      return (
      
        <div className="row col-md-12 m-0 doctr-wraper-border-addimge">
          <div className="fileUpload-medical-journal mr-3 img-upld-healthinfo">
            <span>Upload Image</span>
            <input type="button" id="attach" name="attach" className="upload" />
            <input
              type="file"
              className="upload"
              name="fileText"
              id="fileText"
              onChange={this.fileChange}
            />
          </div>

          <div className="fileUpload-img">
            <img
              // onError={this.srcTypeImage}
              id="profileImage"
              src={this.state.profileImage}
              width="50"
            ></img>
          </div>
       
        </div>
      );
    }
  }

  renderstateCityOrg() {
    if (this.state.healthInfoId > 0) {
      return (
        <Suspense fallback={<div>Loading..</div>}>
          {this.state.organizationIds.length == 0 && (
            <Suspense>
              <div className="hspinfo-contentdiv">
                <label>
                  State<span className="hspifoMantory">*</span>
                </label>
                {/* <div className="col-md-12"> */}
                  <select
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    id="tempState"
                    name="tempState"
                    value={this.state.tempState.value}
                    onInput={this.handleStateCityOrgchangeHandler}
                    onChange={(e) => {
                      this.getStateCityList(e.target.value);
                    }}
                  >
                    <option value="false">Select State</option>
                    {this.state.stateList.map((state) => (
                      <option key={state.stateId}  value={state.stateId}  selected={state.stateId === this.state.tempState.value}>
                        {state.state}
                      </option>
                    ))}
                  </select>
                {/* </div> */}
              </div>
              <div className="hspinfo-contentdiv">
                <label>
                  City<span className="hspifoMantory">*</span>
                </label>
                {/* <div className="col-md-12" style={{ paddingRight: 38 }}> */}
                  <select
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    id="tempCity"
                    name="tempCity"
                    value={this.state.tempCity.value}
                    onInput={this.handleStateCityOrgchangeHandler}
                  >
                    <option value="0">Select City</option>
                    {this.state.cityList.map((city) => (
                      <option key={city.CityId} value={city.CityId}>
                        {city.City}
                      </option>
                    ))}
                  </select>
                  <a
                    onClick={this.addStateCity}
                    style={{ position: "absolute", marginLeft: 10 }}
                  >
                    <img src={Plus} alt="Add" className="img-fluid mt-1" width="24"/>
                  </a>
                {/* </div> */}
              </div>
              {this.state.stateIds.map((s) => (
                <Suspense key={s.stateIds + s.cityIds}>
                  <div className="hspinfo-contentdiv">
                    {/* <div className="col-md-12"> */}
                    <label></label>
                      <input
                        type="text"
                        className="hspInfofieldinput"
                        value={s.stateName}
                        disabled
                      />
                    {/* </div> */}
                  </div>
                  <div className="hspinfo-contentdiv">
                    {/* <div className="col-md-12" style={{ paddingRight: 38 }}> */}
                    <label></label>
                      <input
                        type="text"
                        className="hspInfofieldinput"
                        value={s.cityName}
                        disabled
                      />
                      <a
                        onClick={() => {
                          this.removeStateCity(s.stateIds, s.cityIds);
                        }}
                        style={{ position: "absolute", marginLeft: 10 }}
                      >
                        <img src={Close} alt="Remove" className="img-fluid mt-1"  width="24"/>
                      </a>
                    {/* </div> */}
                  </div>
                </Suspense>
              ))}
            </Suspense>
          )}
          {this.state.stateIds.length == 0 &&
            this.state.organizationIds.length == 0 && (
              <div
                className="row-margin col-md-12"
                style={{ textAlign: "center" }}
              >
                Or
              </div>
            )}

          {this.state.stateIds.length == 0 && (
            <Suspense>
              <div className="hspinfo-contentdiv">
                <label>
                  Organization<span className="hspifoMantory">*</span>
                </label>
                {/* <div className="col-md-12" style={{ paddingRight: 38 }}> */}
                  <select
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    id="tempOrganization"
                    value={this.state.tempOrganization.value}
                    onInput={this.handleStateCityOrgchangeHandler}
                  >
                    <option value="0">select Organization</option>
                    {this.state.organizationList.map((category) => (
                      <option
                        key={category.companyId}
                        value={category.companyId}
                      >
                        {category.companyName}
                      </option>
                    ))}
                  </select>
                  <a
                    onClick={this.addOrganization}
                    style={{ position: "absolute", marginLeft: 10 }}
                  >
                    <img src={Plus} alt="Add" className="img-fluid mt-1" width="24"/>
                  </a>
                {/* </div> */}
              </div>
              <div className="hspinfo-contentdiv"></div>
              
              {this.state.organizationIds.map((org) => (
                <div
                  key={org.pmSiteId}
                  className="hspinfo-contentdiv"
                  style={{ paddingRight: 38 }}
                >
                 <label></label>
                  <input
                    type="text"
                    className="hspInfofieldinput Infoinputwidthdoctr"
                    value={org.siteName}
                    disabled
                  />
                  <a
                    onClick={() => {
                      this.removeOrganization(org.pmSiteId);
                    }}
                    style={{ position: "absolute", marginLeft: 10 }}
                  >
                    <img src={Close} alt="Remove" className="img-fluid mt-1" width="24" />
                  </a>
                </div>
              ))}
            </Suspense>
          )}
        </Suspense>
      );
    }
  }

  render() {
    let healthInfoTab =
      this.state.currentTab == "healthInfoTab" ? " active" : "";
    let imageTab = this.state.currentTab == "imageTab" ? " active" : "";

    return (
      <div className="row healthifo-padng-mob pl-3">
        <div className="ph_drmngmnt col-md-12 pl-0">
          <button
            className={`tab${healthInfoTab}`}
            id="healthInfoTab"
            onClick={this.changeTab}
          >
            Health Information
          </button>
          {this.state.healthInfoId > 0 && (
            <button
              className={`tab${imageTab}`}
              id="imageTab"
              onClick={this.changeTab}
            >
              Image
            </button>
          )}
        </div>
        {this.tabData()}
        <div className="col-md-12 text-right smssetng-btn mb-4 mt-4">
          <a
            className="smssetng-cancelbtn healthinfo-canclbtn"
            onClick={() =>
              this.props.changeMode(
                "list",
                navigation,
                "Add/Edit Health Information"
              )
            }
          >
            Cancel
          </a>
          <a className="smssetng-svebtn" onClick={this.handlesaveHealthInfoDetails}>
            Save
          </a>
        </div>
        </div>
     
    );
  }
}

export default helthinfoAddEdit;
