import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector, } from "react-redux";
import "../css/purpleresponsive.css";
import { Link } from "react-router-dom";
import { apiCalling } from "../apiService";
import WhatsApp from '../home/whatsapp';
import logoImg from '../image/theme/darker-logo.png';
import Fb from '../image/theme/facebook.png';
import Insta from '../image/theme/insta.png';
import FooterImg from '../image/theme/footer-india.png';
import Linkdn from '../image/theme/linkdn.png';
import { setIsAuthenticated } from "../redux/reducers/authSlice";
import { incrementInactivityTime, resetInactivityTime, showWarning } from '../redux/reducers/inactivitySlice';

const Footer = () => {
  const [isLoginPurple] = useState(
    window.localStorage.getItem("isLogin") === "true"
  );
  const dispatch = useDispatch();
  const inactivityTime = useSelector(state => state.inactivity.inactivityTime);
  const warningShown = useSelector(state => state.inactivity.warningShown);
  const scrollToSolution = useCallback(() => {
    const solutionComponent = document.getElementById('solution-component');
    if (solutionComponent) {
      solutionComponent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.location.href = '/';
    }
  }, []);
  useEffect(() => {
    const resetInactivity = () => {
      dispatch(resetInactivityTime());
    };
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    events.forEach(event => window.addEventListener(event, resetInactivity));
    const interval = setInterval(() => {
      dispatch(incrementInactivityTime());
    }, 100); 
    return () => {
      events.forEach(event => window.removeEventListener(event, resetInactivity));
      clearInterval(interval);
    };
  }, [dispatch]);
  useEffect(() => {
    // if (inactivityTime === 1800 && !warningShown&&isLoginPurple) { 
    //   alert('You have been inactive for 30 minutes. Please take action to stay logged in.');
    //   dispatch(showWarning());
    // } else 
    if (inactivityTime >= 2700&&isLoginPurple) { 
      logout();
      // alert('You have been logged out due to inactivity.');
    }
  }, [inactivityTime, warningShown, dispatch]);
  const logout = useCallback(() => {
    destroy();
    localStorage.clear();
    dispatch(setIsAuthenticated(false));
  }, [dispatch]);

  const goBacktoLogOut = useCallback(() => {
    localStorage.clear();
    dispatch(setIsAuthenticated(false));
    window.location.href = "/login";
  }, [dispatch]);

  const destroy = () => {
    const logoutReq = {
      method: "POST",
      body: JSON.stringify({
        functionName: "logout",
        siteId: localStorage.getItem("siteId"),
        userId: localStorage.getItem("userId"),
      }),
    };
    apiCalling(logoutReq).then((data) => {
      if (data.success === "1") {
      localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/";
      alert("Login Timeout");
      }
    });
  };

  return (
    <div className="ph-footer">
      <div className="container-lg">
        <footer className="d-flex flex-wrap justify-content-between">
          <div className="col-lg-4 col-12 col-sm-12 ph-pl-0">
            <div className="ph-footer-logo">
              <img src={logoImg} className="img-fluid" alt="logo" width="150" height="54" />
            </div>
            <div>
              <div className="ph-reg-address">Registered Address :</div>
              <div className="ph-main-address">
                Irish Isle Luxury Villa, Villa #38, Koliyakode, Pothencode, Trivandrum, Kerala, India - 695589
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-12 col-sm-12 ph-pl-0">
            <div className="mb-3 mt-sm-2">
              <a className="ph-links-footer">Links</a>
            </div>
            <div className="d-flex flex-wrap">
              <div className="col-lg-3 col-sm-4 px-0">
                <ul className="nav flex-column">
                  <li className="nav-item ph-footer-navs">
                    <Link to="/" className="nav-link p-0 ph-nav-item">Home</Link>
                  </li>
                  <li className="nav-item ph-footer-navs">
                    <Link to="/about" className="nav-link p-0 ph-nav-item">About Us</Link>
                  </li>
                  <li className="nav-item ph-footer-navs">
                    <Link to="" className="nav-link p-0 ph-nav-item" onClick={scrollToSolution}>Solutions</Link>
                  </li>
                  <li className="nav-item ph-footer-navs">
                    <Link to="/contactus" className="nav-link p-0 ph-nav-item">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 pr-0 col-sm-4 px-0">
                <ul className="nav flex-column">
                  <li className="nav-item ph-footer-navs">
                    <a href="/blogs" className="nav-link p-0 ph-nav-item">Blogs</a>
                  </li>
                  <li className="nav-item ph-footer-navs">
                    <a href="/terms-conditions" className="nav-link p-0 ph-nav-item">Terms & Conditions</a>
                  </li>
                  <li className="nav-item ph-footer-navs">
                    <a href="/privacy-policy" className="nav-link p-0 ph-nav-item">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 pl-0 col-sm-4 px-0">
                <ul className="nav flex-column">
                  <li className="nav-item ph-footer-navs">
                    <a href="/news" className="nav-link p-0 ph-nav-item">News</a>
                  </li>
                  <li className="nav-item ph-footer-navs">
                    <Link to="/resources" className="nav-link p-0 ph-nav-item">Success Stories </Link>
                  </li>
                  <li className="nav-item ml-0" onClick={goBacktoLogOut}>
                    <Link className="nav-link p-0 ph-nav-item" >
                      {window.localStorage?.getItem("isLogin") === "true"
                        ? "Logout"
                        : "Doctor Login"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 px-0 col-sm-12 ph-footer-with-logo">
            <ul className="nav">
              <li className="nav-item">
                <a target="_blank" href="https://www.facebook.com/gopurplehealth" className="nav-link px-0 ph-footer-icons">
                  <img src={Fb} alt="fb" width="50" height="50" />
                </a>
              </li>
              <li className="nav-item">
                <a target="_blank" href="https://www.instagram.com/gopurplehealth?igsh=cm02a21zdjdrb3d6" className="nav-link px-0 ph-footer-icons">
                  <img src={Insta} alt="fb" width="50" height="50" />
                </a>
              </li>
              <li className="nav-item">
                <a target="_blank" href="https://www.linkedin.com/company/purplehealth/mycompany/" className="nav-link px-0 ph-footer-icons">
                  <img src={Linkdn} alt="fb" width="50" height="50" />
                </a>
              </li>
            </ul>
            <div className="ph-footer-right">
              <img src={FooterImg} alt="FooterImg" width="80" height="40" />
            </div>
          </div>
        </footer>
        <WhatsApp />
      </div>
    </div>
  );
};

export default Footer;
