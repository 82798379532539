import React, { Suspense, useEffect, useState } from "react";
import csv from "../image/icons/csv.png";
import Pie from "./rh-pie-chart";
import HorizontalBar from "./rh-horizontal-bar";
import AppointmentConsult from "./rh-appointment-consult";
import BarLine from "./rh-bar-line";
import Header from "./header";
import Footer from "./footer";
import { awsAPIUrlcommonToken } from "../apiService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TextChatReports = () => {
  const [specialityVsConsultList, setSpecialityVsConsultList] = useState([]);
  const [specialityVsConsultGraph, setSpecialityVsConsultGraph] = useState(null);
  const [specialityVsConsultGTotal, setSpecialityVsConsultGTotal] = useState("");
  const [generateDoctorVsConsultList, setGenerateDoctorVsConsultList] = useState([]);
  const [generateDoctorVsConsultGraph, setGenerateDoctorVsConsultGraph] = useState(null);
  const [generateDoctorGTotal, setGenerateDoctorGTotal] = useState("");
  const [appointmentVsConsultList, setAppointmentVsConsultList] = useState([]);
  const [appointmentGTotal, setAppointmentGTotal] = useState("");
  const [appointmentVsConsultGraph, setAppointmentVsConsultGraph] = useState(null);
  const [monthVsConsultList, setmonthVsConsultList] = useState([]);
  const [monthVsConsultGraph, setMonthVsConsultGraph] = useState(null);
  const [monthGTotal, setMonthGTotal] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [startYear, setStartYear] = useState(2015)
  const [years, setYears] = useState([])
  const [fromYear, setFromYear] = useState(new Date().getFullYear())
  const [toYear, setToYear] = useState("")
  const [isLoader, setIsLoader] = useState(true)


  useEffect(() => {
    YearsCount();
    fetchData();
  }, [])

  // useEffect(() => {
  // }, [specialityVsConsultGraph, generateDoctorVsConsultGraph, specialityVsConsultList])

  const YearsCount = () => {
    let yearsList = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearsList.push(year);
    }
    setYears(yearsList)
  }
  const fetchData = () => {
    setIsLoader(true)
    Promise.all([
      SpecialityVsConsult(),
      GenerateDoctorVsConsult(),
      AppointmentVsConsult(),
      MonthVsConsult()
    ]).finally(() => 
    setTimeout(() => {
      setIsLoader(false)
    }, 1000));
  };
  const SpecialityVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateSpecialityVsConsultationReport",
        status: "Yes",
        fromYear: fromYear,
        toYear: toYear
      })
    };
      // console.log("🚀 ~ SpecialityVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
        //  console.log("fileupload Toplist---", data.result[0]);
      if (data.success === "1") {
        setSpecialityVsConsultList(data.result[0].specialityConsultationDetails);
        setSpecialityVsConsultGraph(data.result[0].graphData)
        setSpecialityVsConsultGTotal(data.result[0].grandTotal)
      }
      else {
        setSpecialityVsConsultList([]);
        setSpecialityVsConsultGraph(null)
        setSpecialityVsConsultGTotal("")
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  const GenerateDoctorVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateDoctorVsConsultationReport",
        status: "Yes",
        fromYear: fromYear,
        toYear: toYear
      })
    };
      // console.log("🚀 ~ GenerateDoctorVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
        // console.log("fileupload GenerateDoctorVsConsult---", data.result[0]);
      if (data.success === "1") {
        setGenerateDoctorVsConsultList(data.result[0].doctorConsultationDetails);
        setGenerateDoctorVsConsultGraph(data.result[0].graphData)
        setGenerateDoctorGTotal(data.result[0].grandTotal)
      }
      else {
        setGenerateDoctorVsConsultList([]);
        setGenerateDoctorVsConsultGraph(null)
        setGenerateDoctorGTotal("")
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  const AppointmentVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateAppointmentVsConsultationReport",
        status: "Yes",
        fromYear: fromYear,
        toYear: toYear
      })
    };
      // console.log("🚀 ~ AppointmentVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
        // console.log("fileupload appointmentVsConsult---", data.result[0]);
      if (data.success === "1") {
        setAppointmentVsConsultList(data.result[0].appointmentConsultationDetails);
        setAppointmentGTotal(data.result[0].grandTotal)
        setAppointmentVsConsultGraph(data.result[0].graphData)
      }
      else {
        setAppointmentVsConsultList([]);
        setAppointmentVsConsultGraph(null)
        setAppointmentVsConsultGraph("")
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }
  const MonthVsConsult = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "generateMonthWiseConsultationReport",
        status: "Yes",
        fromYear: fromYear,
        toYear: toYear
      })
    };
      // console.log("🚀 ~ MonthVsConsult ~ apiJson:", apiJson)
    awsAPIUrlcommonToken(apiJson).then((data) => {
        //  console.log("fileupload MonthVsConsultGraph---", data.result[0]);
      if (data.success === "1") {
        setmonthVsConsultList(data.result[0].monthlyConsultationDetails);
        setMonthVsConsultGraph(data.result[0].graphData)
        setMonthGTotal(data.result[0].grandTotal)
      }
      else {
        setmonthVsConsultList([]);
        setMonthVsConsultGraph(null)
        setMonthGTotal("")
      }
    }).catch((error) => {
      console.error("Error in getting uploaded data:", error);
    });

  }

  return (
    <div className="purpleWrap">
      <ToastContainer />
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header />
          <div className="container">
            <div className="main-wrapper1">
              <div className="col-md-12 p-0">
                <ul className="breadcrumb">
                  <li>
                    <a href={"/reliance/dashboard"}>Dashboard</a>
                    <i className="fa fa-chevron-right"></i>
                  </li>
                  <li>RHA Dashboard</li>
                </ul>
              </div>
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-2 col-md-12  mb-2 mb-lg-0">
                  <div className="form-head border-0 mb-0 pb-0">RHA Dashboard</div>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-12">
                  <div className="row">
                    <div className="col-md-4 col-12 text-right mb-3 mb-md-0">
                      <select className="input-design py-2 mb-0" value={fromYear}
                        onChange={(e) => { setFromYear(e.target.value) }} >
                        <option value="">- - From Year- -</option>
                        {years.map((year, index) => (
                          <option value={year} key={index}>{year}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 col-12 text-right">
                      <select className="input-design py-2 mb-0" value={toYear}
                        onChange={(e) => { setToYear(e.target.value) }} >
                        <option value="">- - To Year- -</option>
                        {years.map((year, index) => (
                          <option value={year} key={index}>{year}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 col-12 search-top">
                      <input type="submit" value="Search"
                        className="formButton mt-3 m-md-0 m-lg-0 formButtonsearch-width"
                        onClick={(e) => {
                          if ((fromYear && !toYear) || (toYear >= fromYear)) {
                            fetchData()
                          } else {
                            toast.warning("Please ensure the 'to year' is greater than the 'from year.")
                          }
                        }} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-12 text-right mt-3 mt-lg-0 col-12">

                  <a className="file-upload ml-2 py-2" href="/reliance/rha-fileupload">File Upload</a>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="main-head col-12 mx-0">Speciality vs No. of Consults</div>
                {((specialityVsConsultList.length > 0) && !isLoader) ?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div className="table-responsive table-respons-scroll"
                          style={{
                            maxHeight: specialityVsConsultList.length > 25
                              ? '614px'
                              : specialityVsConsultList.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Speciality Name</th>
                                <th>Consultation Count</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">
                              {(specialityVsConsultList.length > 0 && !isLoader) && (
                                <>
                                {specialityVsConsultList?.map((row, index) => (
                                  <tr key={index}>
                                    <td>{row.Speciality}</td>
                                    <td>{row.consultationCount}</td>
                                  </tr>
                                ))}
                              <tr>
                                <td>Grand Total </td>
                                <td>{specialityVsConsultGTotal}</td> 
                              </tr>
                              </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12">
                      <div className="graph-outer">
                        <div className="form-head text-center">Speciality vs No. of Consults</div>
                        {(specialityVsConsultGraph && !isLoader) && <Pie key={specialityVsConsultGraph} specialityVsConsultGraph={specialityVsConsultGraph} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                      <small>{fromYear && toYear
                        ? `Data for the years ${fromYear} to ${toYear} is currently unavailable.`
                        : `Data for the year ${fromYear || toYear} is currently unavailable.`}</small>
                    </div>
                  </div>
                }
              </div>
              <div className="row my-4">
                <div className="main-head col-12 mx-0">Doctors vs No. of Consults</div>
                {((generateDoctorVsConsultList.length > 0)  && !isLoader)?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div
                          className="table-responsive table-respons-scroll"
                          style={{
                            maxHeight: generateDoctorVsConsultList.length > 25
                              ? '825px'
                              : generateDoctorVsConsultList.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Doctor Name</th>
                                <th>Consultation Count</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">
                              {generateDoctorVsConsultList.length > 0 && (
                                <>
                                {generateDoctorVsConsultList?.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.doctorName}</td>
                                    <td>{list.consultationCount}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>Grand Total </td>
                                  <td>{generateDoctorGTotal}</td>
                                </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12">
                      <div className="graph-outer">
                        <div className="form-head text-center">Doctors vs No. of Consults</div>
                        {generateDoctorVsConsultGraph && <HorizontalBar key={generateDoctorVsConsultGraph} generateDoctorVsConsultGraph={generateDoctorVsConsultGraph} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                      <small>{fromYear && toYear
                        ? `Data for the years ${fromYear} to ${toYear} is currently unavailable.`
                        : `Data for the year ${fromYear || toYear} is currently unavailable.`}</small>
                    </div>
                  </div>
                }
              </div>
              <div className="row my-4">
                <div className="main-head col-12 mx-0">Type of Consults</div>
                {((appointmentVsConsultList.length > 0)  && !isLoader)?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div className="table-responsive table-respons-scroll"
                          style={{
                            maxHeight: appointmentVsConsultList.length > 25
                              ? '614px'
                              : appointmentVsConsultList.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Service Name</th>
                                <th>Consultation Count</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">
                              {appointmentVsConsultList.length > 0 && (
                                <>
                                {appointmentVsConsultList?.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.Service}</td>
                                    <td>{list.consultationCount}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>Grand Total</td>
                                  <td>{appointmentGTotal}</td>
                                </tr>
                                </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12">
                      <div className="graph-outer">
                        <div className="form-head text-center">Type of Consults</div>
                        {appointmentVsConsultGraph && <AppointmentConsult key={appointmentVsConsultGraph} appointmentVsConsultGraph={appointmentVsConsultGraph} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                      <small>{fromYear && toYear
                        ? `Data for the years ${fromYear} to ${toYear} is currently unavailable.`
                        : `Data for the year ${fromYear || toYear} is currently unavailable.`}</small>
                    </div>
                  </div>
                }

              </div>
              <div className="row my-4">
                <div className="main-head col-12 mx-0">Month vs Type of Consults</div>
                {((monthVsConsultList.length > 0)  && !isLoader) ?
                  <>
                    <div className="col-12 col-lg-5 col-md-12">
                      <div className="table-space">
                        <div className="table-responsive  table-respons-scroll"
                          style={{
                            maxHeight: monthVsConsultList.length > 25
                              ? '425px'
                              : monthVsConsultList.length > 10
                                ? '550px'
                                : '350px'
                          }}
                        >
                          <table className="table video_audio_table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th style={{minWidth:"40px"}}>Month Consult</th>
                                <th style={{minWidth:"40px"}}>Text chat</th>
                                <th style={{minWidth:"40px"}}>Video chat</th>
                                <th style={{minWidth:"40px"}}>Grand Total</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "12px" }} className="tableHeight">

                              {monthVsConsultList.length > 0 && (
                                <>
                                {monthVsConsultList?.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.monthConsult}</td>
                                    <td>{list.textchat}</td>
                                    <td>{list.videochat}</td>
                                    <td>{list.total}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td>Grand Total </td>
                                  <td>{monthGTotal[0]?.textchat}</td>
                                  <td>{monthGTotal[0]?.videochat}</td>
                                  <td>{monthGTotal[0]?.grandTotal}</td>
                                </tr>
                                </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12">
                      <div className="graph-outer">
                        <div className="form-head text-center">Month vs Type of Consults</div>
                        {monthVsConsultGraph && <BarLine key={monthVsConsultGraph} monthVsConsultGraph={monthVsConsultGraph} />}
                      </div>
                    </div>
                  </>
                  :
                  <div className="col-12">
                    <div className="no-data-div text-danger">
                      <small>{fromYear && toYear
                        ? `Data for the years ${fromYear} to ${toYear} is currently unavailable.`
                        : `Data for the year ${fromYear || toYear} is currently unavailable.`}</small>
                    </div>
                  </div>
                }
              </div>
            </div>
            {isLoader &&
              <div className="loader d-block"></div>
            }
          </div>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
};
export default TextChatReports;
