import React, { Component, Suspense } from "react";
import $ from "jquery";
import { apiCalling, formatDatePickerToddMMyyyy } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import saveIco from "../../image/icons/save.png";
import ThumbsDown from "../../image/icons/thumbsDown.png";
import ThumbsUp from "../../image/icons/thumbsUp.png";
import Tick from "../../image/icons/tick.png";
import Cancel from "../../image/icons/cancel.png";
import Code from "../../image/icons/code.png";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import EmbedCodePopup from "./embedCodePopup";

class helthInfoList extends Component {
  state = {
    healthInfoData: [],
    totalCount: 0,
    activePage: 1,
    offset: "0",
    maxRow: "10",
    cmbSelect: "",
    searchKeyWord: "",
    cmbCategory: "",
    toDate: "",
    fromDate: "",
    showEmbedCodePopup: false,
  };

  componentDidMount() {
    this.getHealthInfo();
  }

  dateChangeHandler = (date, id) => {
    this.setState({ [id]: formatDatePickerToddMMyyyy(date) });
  };

  async handlePageChange(pageNumber) {
    await this.setState({
      activePage: pageNumber,
      offset: (parseInt(pageNumber) - 1) * 10,
    });
    this.getHealthInfo();
  }

  handleCmbSerchChange = () => {
    var cmbSelectVal = $("#cmbSerch").val() !== "0" ? $("#cmbSerch").val() : "";
    // $("#searchKeyWord").val("")
    $("#searchKeyWord").prop("disabled", cmbSelectVal !== "" ? false : true);
    this.setState({ cmbSelect: cmbSelectVal, searchKeyWord: "" });
    $("#searchKeyWord").focus();
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getHealthInfo() {
    $(".loader").show();
    const healthInfoData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthInfoListing",
        cmbCategory: this.state.cmbSelect,
        txtInput: this.state.searchKeyWord,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        cmbStatus: this.state.cmbSelect,
        max: this.state.maxRow,
        Offset: this.state.offset,
      }),
    };

    apiCalling(healthInfoData).then((data) => {
      if (data.success === "1") {
        var healthInfoData = data.healthInfoData;
        this.setState({
          healthInfoData: healthInfoData,
          totalCount: data.totalCount,
        });
      } else if (data.success === "0") {
        this.setState({ healthInfoData: [], totalCount: 0 });
      }
      $(".loader").hide();
    });
  }

  handleHealthInfoStatusChange(healthInfoId, status) {
    if (status===1) {
            window.confirm("Do you want to Enable this?");
          } else {
            window.confirm("Do you want to Disable this?");
          }
    $(".loader").show();
    const healthInfoStatusChange = {
      method: "POST",
      body: JSON.stringify({
        functionName: "healthInfoStatusChange",
        healthInfoId: healthInfoId,
        status: status,
      }),
    };

    apiCalling(healthInfoStatusChange).then((data) => {
      if (data.success === "1") {
        this.getHealthInfo();
        // alert("Sucess");
      } else {
        alert("Error occurs..!");
      }
      $(".loader").hide();
    });
  }

  handleApproveHealthInfo(healthInfoId, isVerified) {
    if (isVerified===1) {
      window.confirm("Do you want to Disapprove this?");
    } else {
      window.confirm("Do you want to Approve this?");
    }
    $(".loader").show();
    const approveHealthInfo = {
      method: "POST",
      body: JSON.stringify({
        functionName: "approveHealthInfo",
        healthInfoId: healthInfoId,
        isVerified: isVerified,
      }),
    };

    apiCalling(approveHealthInfo).then((data) => {
      // console.log(data,'approveeeee');
      if (data.success === "1") {
        this.getHealthInfo();
        // alert("Sucess");
      } else {
        alert("Error occurs..!");
      }
      $(".loader").hide();
    });
  }

  saveSortOrder(helthInfo) {
    $(".loader").show();
    const healthInfoData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "saveSortOrder",
        healthInfoId: helthInfo.healthInfoId,
        sortOrder: helthInfo.sortOrder,
      }),
    };

    apiCalling(healthInfoData).then((data) => {
      if (data.success === "1") {
        alert("Sort order changed");
      } else {
        alert("Unable to change the Sort order");
      }
      $(".loader").hide();
    });
  }

  changeSortOrder = (e, healthInfo) => {
    let healthInfoData = this.state.healthInfoData;
    let healthInfoIdIndx = healthInfoData.indexOf(healthInfo);
    healthInfoData[healthInfoIdIndx].sortOrder = e.target.value;
    this.setState({ healthInfoData });
  };

  renderHealthInfoData() {
    const navigationEdit = [
      { pageName: "Dashboard", url: "/admin/dashboardAdmin" },
      {
        pageName: "Health info Dashboard",
        url: "/adminManagement/healthInformation",
      },
      {
        pageName: "Edit Health Info",
        url: "/adminManagement/healthInfo",
        current: true,
      },
    ];
    if (this.state.healthInfoData.length > 0) {
      return this.state.healthInfoData.map((helthInfo) => (
        <tr key={helthInfo.healthInfoId} className="health-info-table-list">
          <td>{helthInfo.healthInfoId}</td>
          <td>{helthInfo.title}</td>
          <td>{helthInfo.shortDescription}</td>
          <td>{helthInfo.createdBy}</td>
          <td>{helthInfo.postedDate}</td>
          <td className="row" style={{ margin: "0px", paddingLeft: "0px" ,borderRight:"none", borderLeft:"none"}}>
            <div
              className="col-sm-9"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <input
                type="text"
                value={helthInfo.sortOrder > 0 ? helthInfo.sortOrder : 0}
                className="hspmngInput"
                style={{ width: "100%" }}
                name="sortOrder"
                id="sortOrder"
                maxLength="3"
                onInput={(e) => {
                  this.changeSortOrder(e, helthInfo);
                }}
              ></input>
            </div>
            <div className="col-sm-3" style={{ padding: "0px" }}>
              <a className="mr-2" onClick={() => this.saveSortOrder(helthInfo)}>
                <img
                  src={saveIco}
                  alt="Save"
                  width="15"
                  className="img-fluid cursorpointer"
                />
              </a>
            </div>
          </td>
          <td>{helthInfo.likeCount > 0 ? helthInfo.likeCount : 0}</td>
          <td>{helthInfo.readCount > 0 ? helthInfo.readCount : 0}</td>
          <td>{helthInfo.articleStatus ? "Published" : "Draft"}</td>
          <td style={{ width: "10vw" }} className="nowrap ph_siteedit">
            <span className="col-sm-3 p-0">
              <a
                onClick={() =>
                  this.props.changeMode(
                    "edit",
                    navigationEdit,
                    "Edit Health Information",
                    helthInfo.healthInfoId
                  )
                }
              >
                <img src={Edit} alt="Edit" width="18" className="img-fluid cursorpointer" />
              </a>
            </span>
            <span className="col-sm-3  p-1">
              <a
                onClick={() =>
                  this.handleHealthInfoStatusChange(
                    helthInfo.healthInfoId,
                    helthInfo.articleStatus ? 0 : 1
                  )
                }
                title={
                  helthInfo.articleStatus
                    ? "Click to Deactivate"
                    : "Click to Activate"
                }
              >
                <img
                  src={helthInfo.articleStatus ?  Tick : Cancel}
                  alt={helthInfo.articleStatus ? "Activate" : "Deactivate"}
                  width="18"
                  className="img-fluid cursorpointer"
                />
              </a>
            </span>
            <span className="col-sm-3  p-0">
              <a
                onClick={() =>
                  this.handleApproveHealthInfo(
                    helthInfo.healthInfoId,
                    helthInfo.isVerified ? 0 : 1
                  )
                }
                title={
                  helthInfo.isVerified
                    ? "Click to Approve"
                    : "Click to Disapprove"
                }
              >
                <img
                  src={helthInfo.isVerified ? ThumbsDown : ThumbsUp}
                  alt={helthInfo.articleStatus ? "Disapprove" : "Approve"}
                  width="22"
                  className="img-fluid cursorpointer"
                />
              </a>
            </span>
            <span className="col-sm-3  p-0">
              <a
                onClick={() => this.setState({ showEmbedCodePopup: true })}
                title="Embed Code"
              >
                <img
                  src={Code}
                  alt="Embed Code"
                  width="22"
                  className="img-fluid cursorpointer"
                />
              </a>
            </span>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="10" className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  }

  render() {
    const navigationAdd = [
      { pageName: "Dashboard", url: "/admin/dashboardAdmin" },
      {
        pageName: "Health info Dashboard",
        url: "/adminManagement/healthInformation",
      },
      {
        pageName: "Add Health Info",
        url: "/adminManagement/healthInfo",
        current: true,
      },
    ];

    return (
      <div className="HosptlMangemntTable">
        <div className="AddNewHspbtnDiv">
          <a
            className="addnewhspbtn"
            onClick={() =>
              this.props.changeMode("addNew", navigationAdd, "Add Health Info")
            }
          >
            Add Health Info
          </a>
        </div>

        <div className="tableHeader-HspMangemnt mt-1 tableHeader-healthifo">
          <div className="selectField-HspMangmnt">
            <select
              className="slecthsp slecthspMob"
              id="cmbSerch"
              onChange={() => this.handleCmbSerchChange()}
            >
              <option value="0">Search In</option>
              <option value="Id">Id</option>
              <option value="CreatedBy">Created By</option>
              <option value="Title">Title</option>
            </select>
          </div>
          <div className="selectField-HspMangmnt">
            <input
              type="text"
              className="hspmngInput doctlistInputmob"
              name="searchKeyWord"
              id="searchKeyWord"
              disabled
              value={this.state.searchKeyWord}
              onInput={this.changeHandler}
            />
          </div>
          <div className="selectField-HspMangmnt date-healthinfo-header">
            <DatePicker
              id="fromDate"
              name="fromDate"
              placeholderText="From"
              // maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              onSelect={(date) => this.dateChangeHandler(date, "fromDate")}
              value={this.state.fromDate}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
              tabIndex="3"
              onKeyDown={() => this.disableDateField("fromDate")}
              className="hspmngInput healthInput-radius-date doctlistInputmob"
            />
          </div>
          <div className="selectField-HspMangmnt date-healthinfo-header">
            <DatePicker
              id="toDate"
              name="toDate"
              placeholderText="To"
              // maxDate={new Date()}
              className="hspmngInput doctlistInputmob healthInput-radius-date"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode="scroll"
              peekNextMonth
              disabledKeyboardNavigation
              onSelect={(date) => this.dateChangeHandler(date, "toDate")}
              value={this.state.toDate}
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-date-picker"
              autoComplete="off"
              tabIndex="3"
              onKeyDown={() => this.disableDateField("toDate")}
            />
          </div>
          <div className="selectField-HspMangmnt">
            <select
              className="slecthsp slecthspMob"
              id="cmbSerch"
              onChange={() => this.handleCmbSerchChange()}
            >
              <option value="0">Status</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
              <option value="10">Draft</option>
              <option value="11">Published</option>
            </select>
          </div>
          <div className="selectField-HspMangmnt">
            <button
              className="searchbtn-hspMng"
              onClick={() => this.getHealthInfo()}
            >
              Search
            </button>
          </div>
        </div>

        <table className="table table-bordered-hsp table-responsive">
          <thead>
            <tr>
              <th className="tablehead-hspmng" width="5%">
                ID
              </th>
              <th className="tablehead-hspmng" width="15%">
                Title
              </th>
              <th className="tablehead-hspmng" width="15%">
                Short Description
              </th>
              <th className="tablehead-hspmng" width="15%">
                Created By
              </th>
              <th className="tablehead-hspmng" width="10%">
                Posted Date
              </th>
              <th className="tablehead-hspmng" width="10%">
                Sort Order
              </th>
              <th className="tablehead-hspmng" width="5%">
                Like Count
              </th>
              <th className="tablehead-hspmng" width="5%">
                Read Count
              </th>
              <th className="tablehead-hspmng" width="10%">
                Article Status
              </th>
              <th className="tablehead-hspmng" width="10%">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{this.renderHealthInfoData()}</tbody>
        </table>
        {this.state.totalCount > 5 ? (
          <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={10}
              onChange={(pageNumber) => this.handlePageChange(pageNumber)}
            />
          </div>
        ) : (
          ""
        )}

        {this.state.showEmbedCodePopup == true && (
          <EmbedCodePopup
            showEmbedCodePopup={(data) =>
              this.setState({ showEmbedCodePopup: data })
            }
            changeHandler={this.changeHandler}
          />
        )}
      </div>
    );
  }
}

export default helthInfoList;
