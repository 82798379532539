import { awsUrl, awsUrlcommon, apiUrlReliance, awsUrlcommonToken } from "./config";
var CryptoJS = require("crypto-js");

const apiUrl = awsUrl();
const apiUrlcommon = awsUrlcommon();
const apiUrlRelianceConst = apiUrlReliance();
const apiUrlcommonToken = awsUrlcommonToken();
const ENC_KEY = "p!u@r#p$l%e^h&e*a!l@t#h$a%d^mi&n";

export function Captcha() {
  var alpha = new Array(
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z"
  );
  var i;
  for (i = 0; i < 6; i++) {
    var a = alpha[Math.floor(Math.random() * alpha.length)];
    var b = alpha[Math.floor(Math.random() * alpha.length)];
    var c = alpha[Math.floor(Math.random() * alpha.length)];
    var d = alpha[Math.floor(Math.random() * alpha.length)];
    var e = alpha[Math.floor(Math.random() * alpha.length)];
    var f = alpha[Math.floor(Math.random() * alpha.length)];
    var g = alpha[Math.floor(Math.random() * alpha.length)];
  }
  var code = a + " " + b + " " + c + " " + d + " " + e + " " + f;
  return code;
}
export function ValidCaptcha(mainCaptcha, txtInput) {
  var string1 = mainCaptcha;
  var string2 = txtInput;
  if (string1 == string2) {
    return true;
  } else {
    return false;
  }
}

export function removeSpaces(string) {
  return string.split(" ").join("");
}
export function validateEmail(email) {
  const re = /^[a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  return re.test(email);
}
export function phonenumberValidation(inputtxt) {
  var mob = /^[1-9]{1}[0-9]{9}$/;
  if (mob.test(inputtxt) == false) {
    return false;
  }
  return true;
}

export function signupValidation(
  firstName,
  lastName,
  emailId,
  mobileNo,
  password,
  cpassword,
  textCaptcha,
  hiddenCaptcha
) {
  var message = "";
  var field = "xxx";
  let checkCaptch = ValidCaptcha(textCaptcha, hiddenCaptcha);
  if (!firstName) {
    message = "Please Enter First Name Properly";
    field = "firstName";
  } else if (!lastName) {
    message = "Please Enter Last Name Properly";
    field = "lastName";
  } else if (!emailId) {
    message = "Please Enter Your Email Id ";
    field = "emailId";
  } else if (!mobileNo) {
    message = "Please Enter your mobile no";
    field = "mobileNo";
  } else if (!password) {
    message = "Enter your password properly";
    field = "password";
  } else if (!cpassword) {
    message = "Enter confirm password properly";
    field = "cpassword";
  } else if (password != cpassword) {
    message = "Your Passwords are not matched please check properly";
  } else if (!checkCaptch) {
    message = "Captcha validation failed , please try again";
  } else {
    message = "";
  }

  return [message, field];
}
export function changeDateFormat(appDate) {
  let newAppDate = "";
  if (appDate) {
    const a = appDate.split("-");
    newAppDate = a[2] + "/" + a[1] + "/" + a[0];
  }
  return newAppDate; //dd/mm/yyyy
}
// export function apiCalling(requestOptions) {
//   return fetch(apiUrl, requestOptions)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function awsAPIUrlcommon(req) {
//   return fetch(apiUrlcommon, req)
//     .then((data) => data.json())
//     .then((res) => res);
// }
// export function apiUrlRelianceFunction(requestOptions) {
//   return fetch(apiUrlRelianceConst, requestOptions)
//     .then((data) => data.json())
//     .then((res) => res);
// }
export function prependZero(v) {
  if (v < 9) return "0" + v;
  else return v;
}

var currentHours = 0;
var currentMinutes = 0;
var currentSeconds = 0;
currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
currentHours = (currentHours < 10 ? "0" : "") + currentHours;
export function updateClock() {
  currentSeconds++;
  var currentTimeString = "00:00:00";
  if (currentSeconds > 59) {
    currentMinutes++;
    currentSeconds = 0;
    currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
  }
  if (currentMinutes > 59) {
    currentHours++;
    currentMinutes = 0;
  }
  currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;

  currentTimeString =
    currentHours + ":" + currentMinutes + ":" + currentSeconds;
  return currentTimeString;
}

export function formatDatePickerToddMMyyyy(date) {
  if (date == null) {
    return "";
  } else {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month = mm < 10 ? "0" + mm : mm;
    return day + "/" + month + "/" + date.getFullYear();
  }
}

export function apiCalling(requestOptions) {
  var parameters = JSON.parse(requestOptions.body);

  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  // console.log("body:", requestOptions.body);
  // console.log("ciphertext:", ciphertext);
  // let tokenData = {
  //   method: "POST",
  //   body: JSON.stringify({ token: ciphertext }),
  // };
  return fetch(apiUrl, requestOptions)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      // console.log(decryptedData,"decryptedDatadecryptedData");
      return decryptedData;
     
    });   
}
export function apiUrlRelianceFunction(requestOptions) {
  var parameters = JSON.parse(requestOptions.body);
  // console.log("body:", requestOptions.body);
  var cipher = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  let requestParameter = {
    method: "POST",
    body: JSON.stringify({ token: cipher }),
  };
  // console.log("requestParameter:", requestParameter);

  return fetch(apiUrlRelianceConst, requestParameter)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      // console.log("decryptedData", decryptedData);
      return decryptedData;
    });
}

export function awsAPIUrlcommon(req) {
  var parameters = JSON.parse(req.body);
  var cipher = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  // console.log("parameters", cipher);
  decryptData(cipher);
  // let requestParameter = {
  //   method: "POST",
  //   body: JSON.stringify({ token: cipher }),
  // };
  return fetch(apiUrlcommon, req)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      // console.log("decryptedData", decryptedData);
      return decryptedData;
    });
}

export function awsAPIUrlcommonToken(req) {
  // console.log("body:", req);
  var parameters = JSON.parse(req.body);
  var cipher = CryptoJS.AES.encrypt(
    JSON.stringify(JSON.stringify(parameters)),
    ENC_KEY
  ).toString();
  let requestParameter = {
    method: "POST",
    body: JSON.stringify({ token: cipher }),
  };
  //  console.log("requestParameter:", requestParameter);

  return fetch(apiUrlcommonToken, requestParameter)
    .then((data) => data.json())
    .then((res) => {
      var bytes = CryptoJS.AES.decrypt(res.token, ENC_KEY);
      var decryptedData = JSON.parse(
        JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      );
      // console.log("decryptedData", decryptedData);
      return decryptedData;
    });
}

export function decryptData(reqBody) {
  try {
    //   let reqBody = {
    //     functionName: "getsitedetail",
    //     siteUrl: "",
    //     storeId: "",
    //     sellerId: "",
    //     categoryId: "1",
    //   };
    var bytes = CryptoJS.AES.decrypt(reqBody, ENC_KEY);
    var decryptedData = JSON.parse(
      JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    );
    // console.log("decryptedDatadecryptedDatadecryptedData", decryptedData);
  } catch (e) {
    return e.errorMessage;
  }
}
