import { createSlice } from '@reduxjs/toolkit';

export const inactivitySlice = createSlice({
    name: 'inactivity',
    initialState: {
        inactivityTime: 0,
        warningShown: false,
    },
    reducers: {
        incrementInactivityTime: (state) => {
            state.inactivityTime += 1;
        },
        resetInactivityTime: (state) => {
            state.inactivityTime = 0;
            state.warningShown = false;
        },
        showWarning: (state) => {
            state.warningShown = true;
        },
    },
});

export const { incrementInactivityTime, resetInactivityTime, showWarning } = inactivitySlice.actions;
export default inactivitySlice.reducer;
